/* stylelint-disable rule-empty-line-before */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/z-index" as zIndex;
@use "settings/box-shadow" as boxShadow;

// =============================================================================
// Variables
// =============================================================================
$_header-banner-height: _s.step(5);
$_header-topbar-height: _s.step(8);

// =============================================================================
// Root
// =============================================================================
.c-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: zIndex.$foreground + 1;

	width: 100%;
	min-height: $_header-topbar-height;

	background-color: _c.set("background white");

	& + main {
		// Add some margin top to the main content, because the header is fixed
		margin-top: $_header-topbar-height;

		@include _b.at("viewport-9") {
			margin-top: _s.rem(83px, false);
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-header__top-banner {
	min-height: $_header-banner-height;
}

.c-header__topbar-container {
	position: fixed;
	left: 0;
	z-index: zIndex.$foreground;

	width: 100%;

	border-bottom: borders.$base;
	background-color: _c.set("background white");

	@include _b.at("viewport-9") {
		position: static;

		width: auto;

		border-bottom: none;
	}
}

.c-header__topbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: $_header-topbar-height;

	@include _b.at("viewport-9") {
		width: auto;
	}
}

.c-header__logo {
	min-height: _s.rem(44px, false);
}

.c-header__hamburger {
	margin-right: _s.rem(-8px);

	@include _b.at("viewport-9") {
		display: none;
	}
}

.c-header__logo {
	display: flex;
	width: _s.rem(100px, false);
	height: _s.rem(10px, false);

	// Change the color of the letters in the logo
	> svg {
		width: 100%;
		height: 100%;

		fill: _c.set("text dark");
	}

	@include _b.at("viewport-9") {
		width: _s.rem(150px, false);
		height: _s.rem(15px, false);
	}
}

.c-header__nav {
	position: fixed;
	top: $_header-topbar-height;
	left: 0;

	width: 100%;
	height: calc(100vh - #{$_header-topbar-height});
	overflow-y: scroll;
	padding: _s.step(4) 0;

	background-color: _c.set("background white");

	transform: translateX(100%);
	transition: transform 0.3s ease-in-out;

	@include _b.at("viewport-9") {
		position: static;

		display: flex;
		width: auto;
		height: auto;
		overflow: initial;
		padding: _s.step(2) 0;

		transform: translateX(0);
	}
}

.c-header__list,
.c-header__sub-list {
	padding: 0;

	list-style: none;
}

.c-header__list {
	@include _b.at("viewport-9") {
		display: flex;
		margin-right: _s.step(2);
	}
}

.c-header__sub-list {
	@include _b.at("viewport-9") {
		position: absolute;
		top: 100%;
		left: 50%;

		padding: _s.step(1) 0;

		background-color: _c.set("background white");
		box-shadow: boxShadow.$box-shadow;
		opacity: 0;

		transform: translateX(-50%) translateY(-10%);
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

		pointer-events: none;

		&:focus-within {
			opacity: 1;

			transform: translateX(-50%) translateY(0);

			pointer-events: auto;
		}
	}
}

.c-header__sub-toggle {
	appearance: none;
	border: none;
	background-color: transparent;

	@include _b.at("viewport-9") {
		&:focus {
			+ .c-header__sub-list {
				opacity: 1;

				transform: translateX(-50%) translateY(0);

				pointer-events: auto;
			}
		}
	}
}

.c-header__sub-toggle-icon {
	width: _s.rem(12px, false);
	height: _s.rem(12px, false);
}

.c-header__list-item,
.c-header__sub-item {
	position: relative;

	display: flex;
	flex-direction: column;

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: _s.rem(4px, false);
		height: 100%;

		background-color: _c.set("border secondary");

		transform: scaleY(0);
		transition: transform 0.3s ease-in-out;
	}
}

.c-header__list-item {
	@include _b.at("viewport-9") {
		position: relative;

		margin-right: _s.step(2);
		margin-left: _s.step(2);
	}

	@include _b.at("viewport-12") {
		margin-right: _s.step(3);
		margin-left: _s.step(3);
	}
}

.c-header__list-link,
.c-header__sub-link {
	text-decoration: none;

	color: _c.set("text paragraph");
}

.c-header__list-link {
	padding: _s.step(1) _s.step(2);

	@include _b.at("viewport-4") {
		padding-right: _s.step(3);
		padding-left: _s.step(3);
	}

	@include _b.at("viewport-7") {
		padding-right: _s.step(4);
		padding-left: _s.step(4);
	}

	@include _b.at("viewport-9") {
		padding-right: 0;
		padding-left: 0;

		&::before {
			content: "";

			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: _s.rem(2px, false);

			background-color: _c.set("border secondary");

			transform: scaleX(0);
			transition: transform 0.3s ease-in-out;
		}

		&:focus {
			~ .c-header__sub-list {
				opacity: 1;

				transform: translateX(-50%) translateY(0);

				pointer-events: auto;
			}
		}
	}
}

.c-header__collapse {
	/**
	 * Add padding to the collapse header directly,
	 * to keep the whole element clickable.
	 */
	.c-collapse__header {
		padding-right: _s.step(2);
		padding-left: _s.step(2);

		@include _b.at("viewport-4") {
			padding-right: _s.step(3);
			padding-left: _s.step(3);
		}

		@include _b.at("viewport-7") {
			padding-right: _s.step(4);
			padding-left: _s.step(4);
		}
	}
}

.c-header__sub-link {
	padding: _s.step(1) _s.step(2) _s.step(1) _s.step(4);

	@include _b.at("viewport-4") {
		padding-right: _s.step(3);
		padding-left: _s.step(5);
	}

	@include _b.at("viewport-7") {
		padding-right: _s.step(4);
		padding-left: _s.step(6);
	}

	@include _b.at("viewport-9") {
		min-width: 100%;
		padding: _s.step(1) _s.step(3);

		white-space: nowrap;
	}
}

.c-header__sub-item-content {
	position: relative;

	display: inline-flex;

	text-transform: capitalize;
}
.c-header__sub-item-content-detail {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;

	color: #8997f9;
}

.c-header__sub-item-badge {
	position: absolute;
	top: 0;
	right: _s.rem(-20px, false);
}

.c-header__actions {
	margin: _s.step(4) _s.step(2) 0;

	@include _b.at("viewport-4") {
		margin-right: _s.step(3);
		margin-left: _s.step(3);
	}

	@include _b.at("viewport-7") {
		margin-right: _s.step(4);
		margin-left: _s.step(4);
	}

	@include _b.at("viewport-9") {
		margin: 0;
	}
}

.c-header__link {
	width: 100%;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-header__list-item--mobile {
	@include _b.at("viewport-9") {
		display: none;
	}
}

.c-header__list-item--desktop {
	@include _b.to("viewport-9") {
		display: none;
	}
}

.c-header--inverted {
	.c-header__topbar-container {
		background-color: _c.set("background dark");
		border-bottom: borders.$dark;
	}

	.c-header__logo {
		// Change the color of the letters in the logo
		> svg {
			fill: _c.set("text white");
		}
	}

	.c-header__nav {
		background-color: _c.set("background dark");
	}

	.c-header__list-link,
	.c-header__sub-link,
	.c-header__collapse {
		text-decoration: none;

		color: _c.set("text white");
	}

	@include _b.at("viewport-9") {
		background-color: _c.set("background dark");

		.c-header__topbar-container {
			border-bottom: none;
		}

		.c-header__sub-link {
			color: _c.set("text paragraph");
		}

		.c-header__sub-toggle {
			color: _c.set("text white");
		}
	}
}

.c-header--light {
	background-color: _c.set("background light");

	.c-header__topbar-container {
		background-color: _c.set("background light");
	}

	.c-header__nav {
		background-color: _c.set("background light");
	}

	@include _b.at("viewport-9") {
		background-color: _c.set("background light");

		.c-header__topbar-container {
			border-bottom: none;
		}
	}
}

.c-header__list-item--has-sublist {
	@include _b.at("viewport-9") {
		flex-direction: row;

		&:hover,
		&:focus {
			.c-header__sub-list {
				opacity: 1;

				transform: translateX(-50%) translateY(0);

				pointer-events: auto;
			}
		}
	}
}

// =============================================================================
// States
// =============================================================================
.c-header {
	&.is-open {
		.c-header__nav {
			transform: translateX(0);
		}
	}
}

.c-header__list-item {
	&.is-active,
	&:hover {
		@include _b.to("viewport-9") {
			background-color: _c.set("background light");

			&:not(.c-header__list-item--has-sublist) {
				&::before {
					transform: scaleY(1);
				}
			}
		}

		@include _b.at("viewport-9") {
			&:not(.c-header__list-item--has-sublist) {
				.c-header__list-link {
					&::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}

.c-header__sub-item {
	&.is-active,
	&:hover {
		@include _b.to("viewport-9") {
			background-color: _c.set("background light");

			&::before {
				transform: scaleY(1);
			}
		}
	}
}

.c-header--inverted {
	.c-header__list-item {
		&.is-active,
		&:hover {
			@include _b.to("viewport-9") {
				background-color: _c.set("background secondary-dark");
			}
		}
	}

	.c-header__sub-item {
		&.is-active,
		&:hover {
			.c-header__sub-link {
				@include _b.to("viewport-9") {
					background-color: _c.set("background secondary-dark");
				}
			}
		}
	}
}

.c-header--top-banner {
	& + main {
		margin-top: $_header-topbar-height + $_header-banner-height;
	}

	.c-header__nav {
		top: $_header-topbar-height + $_header-banner-height;
	}
}
