// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Root
// =============================================================================
.c-badge {
	@include _t.set("badge");

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: _s.rem(16px);
	height: _s.rem(16px);

	text-align: center;

	color: _c.set("text paragraph");
	background-color: _c.set("background secondary");
	border-radius: 100%;
}
