/* stylelint-disable at-rule-empty-line-before, rule-empty-line-before */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Variables
// =============================================================================
$_section-spacing-base: _s.rem(80px);
$_section-spacing-mobile: _s.rem(42px, false);

// =============================================================================
// Root
// =============================================================================

/**
 * Recurring design pattern used, high-level, on the page to split up the
 * page structure into parts where they are separated by spacing.
 */

.l-section {
	overflow-x: hidden;
	padding: 2.63rem 0;

	@include _b.at("viewport-7") {
		padding: 5rem 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.l-section__header {
	+ .l-section__content {
		margin-top: _s.step(3);
	}

	@include _b.at("viewport-7") {
		max-width: 50rem;
		margin-right: auto;

		+ .l-section__content {
			margin-top: _s.step(8);
		}
	}
}

.l-section__intro {
	margin-top: _s.step(1);
}

.highlight__container {
	display: flex;
	justify-content: space-around;
	align-items: center;

	@include _b.between("viewport-3", "viewport-7") {
		flex-direction: column;
		justify-content: center;
		align-items: space-evenly;
	}
	@include _b.at("viewport-7") {
		flex-wrap: wrap;
		align-items: space-around;
	}
	@include _b.at("viewport-12") {
		flex-wrap: none;
	}
}

.highlight__block {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 20%;
	height: 30vw;
	@include _b.between("viewport-3", "viewport-7") {
		width: 90%;
		height: auto;
		margin: 30px 0;
	}
	@include _b.at("viewport-7") {
		width: 40%;
		height: auto;
		margin: 30px 0;
	}
	@include _b.at("viewport-12") {
		width: 20%;
		height: auto;
	}
	img {
		height: 185px;
		margin: 10px 0;
		@include _b.between("viewport-3", "viewport-7") {
			width: 50vw;
		}
	}
}

.highlight__block-title {
	margin: 10px 0;

	font-size: 1.3rem;
	font-weight: 500;
	text-align: center;

	color: #8997f9;
}

.highlight__block-description {
	max-height: 100px;
	margin: 10px 0;

	font-size: 1rem;
	font-weight: normal;
	text-align: center;

	color: #f9fafe;
}

// =============================================================================
// Modifiers
// =============================================================================
.l-section--small {
	overflow: hidden;
	padding: _s.step(4) 0;

	@include _b.at("viewport-7") {
		padding: _s.step(6) 0;
	}
}

.l-section--spacing-after {
	padding-bottom: $_section-spacing-mobile * 4;

	@include _b.at("viewport-7") {
		padding-bottom: $_section-spacing-base * 2;
	}
}

.l-section--spacing-before {
	padding-top: $_section-spacing-mobile * 4;

	@include _b.at("viewport-7") {
		padding-top: $_section-spacing-base * 2;
	}
}

.l-section__header--centered {
	margin: auto;
	margin-top: 50px;

	text-align: center;
}

.l-section__header--centered\@at\:viewport-7 {
	@include _b.at("viewport-7") {
		margin: auto;

		text-align: center;
	}
}
