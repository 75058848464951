// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-tag {
	@include _t.set("small");

	padding: _s.step(1) _s.step(2);

	color: _c.set("text white");
	background-color: _c.set("background secondary");
	border-radius: _s.rem(100px, false);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-tag--dark {
	color: _c.set("text paragraph-inverse");
	background-color: _c.set("background secondary-dark");
}
