// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
.c-logo {
	display: block;
	min-width: _s.rem(90px, false);
	min-height: _s.rem(40px);
	margin: auto;

	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom center;

	@include _b.at("viewport-7") {
		min-height: _s.rem(70px, false);
		margin: _s.rem(8px);

		transform: scale(1);
		transition: transform 0.3s ease-in-out;
	}

	&:hover {
		transform: scale3d(1.05, 1.05, 1.05);
	}
}
