/* stylelint-disable rule-empty-line-before */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Root
// =============================================================================

/**
 * Selector should only apply styles to elements without class. Always add
 * margin-top to the elements based on the element that comes before.
 */

.s-wysiwyg {
	h1 {
		@include _t.set("h2");

		margin-top: _s.step(8);
	}

	h2 {
		@include _t.set("h2");

		margin-top: _s.step(7);
	}

	h3 {
		@include _t.set("h3");

		margin-top: _s.step(6);
	}

	h4 {
		@include _t.set("h4");

		margin-top: _s.step(5);
	}

	h5 {
		@include _t.set("h5");

		margin-top: _s.step(4);

		& + h6 {
			margin-top: _s.step(2);
		}

		& + p {
			margin-top: _s.step(1);
		}
	}

	h6 {
		@include _t.set("h6");

		margin-top: _s.step(3);

		color: _c.set("text highlight");

		& + p {
			margin-top: _s.step(1);
		}
	}

	p {
		margin-top: _s.step(2);
	}

	ul,
	ol {
		margin-top: _s.step(2);
		padding-left: _s.step(3);

		li {
			margin-top: _s.step(1);
		}
	}

	a {
		text-decoration: none;
		word-break: break-all;

		color: _c.set("text highlight");
		border-bottom: solid 1px currentColor;

		transition: color 0.3s ease-in-out;

		&:hover {
			color: _c.set("text paragraph");
		}
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.s-wysiwyg--large {
	h1 {
		@include _t.set("h2");

		margin-top: _s.step(8);
	}

	h2 {
		@include _t.set("h2");

		margin-top: _s.step(7);
	}

	h3 {
		@include _t.set("h3");

		margin-top: _s.step(6);
	}

	h4 {
		@include _t.set("h4");

		margin-top: _s.step(5);
	}

	h5 {
		@include _t.set("h5");

		margin-top: _s.step(4);

		& + h6 {
			margin-top: _s.step(2);
		}

		& + p {
			margin-top: _s.step(1);
		}
	}

	h6 {
		@include _t.set("h6");

		margin-top: _s.step(3);

		color: _c.set("text highlight");

		& + p {
			margin-top: _s.step(1);
		}
	}

	p {
		@include _t.set("large");

		margin-top: _s.step(2);
	}

	ul,
	ol {
		margin-top: _s.step(2);
		padding-left: _s.step(3);

		li {
			margin-top: _s.step(1);
		}
	}

	a {
		text-decoration: none;
		word-break: break-all;

		color: _c.set("text highlight");
		border-bottom: solid 1px currentColor;

		transition: color 0.3s ease-in-out;

		&:hover {
			color: _c.set("text paragraph");
		}
	}
}

.s-wysiwyg--small {
	p {
		@include _t.set("small");

		margin-top: _s.step(2);
	}
}

.s-wysiwyg--figcaption {
	p {
		color: _c.set("text figcaption");
	}

	a {
		color: _c.set("text figcaption");

		&:hover {
			color: _c.set("text dark");
		}
	}
}

.banner-parent-center {
	display: flex;
	justify-content: center;
}

.s-wysiwyg--blog {
	h1 {
		@include _t.set("h2");

		margin-top: _s.step(8);
	}

	h2 {
		&:not(.c-banner__title) {
			@include _t.set("h3");

			margin-top: _s.step(7);

			& + p {
				margin-top: _s.step(1);
			}
		}
	}

	h3 {
		@include _t.set("h4");

		margin-top: _s.step(6);

		& + p {
			margin-top: _s.step(1);
		}
	}

	h4 {
		@include _t.set("h5");

		margin-top: _s.step(5);

		& + p {
			margin-top: _s.step(1);
		}
	}

	h5 {
		@include _t.set("h6");

		margin-top: _s.step(4);

		& + h6 {
			margin-top: _s.step(2);
		}

		& + p {
			margin-top: _s.step(1);
		}
	}

	h6 {
		@include _t.set("h6");

		margin-top: _s.step(3);

		color: _c.set("text highlight");

		& + p {
			margin-top: _s.step(1);
		}
	}

	p {
		@include _t.set("medium");

		margin-top: _s.step(4);
	}

	ul,
	ol {
		margin-top: _s.step(4);
		padding-left: _s.step(3);

		li {
			@include _t.set("medium");

			margin-top: _s.step(1);
		}
	}

	a {
		text-decoration: none;

		color: _c.set("text highlight");
		border-bottom: solid 1px currentColor;

		transition: color 0.3s ease-in-out;

		&:hover {
			color: _c.set("text paragraph");
		}
	}

	img {
		margin: _s.step(8) 0 _s.step(4);

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	aside {
		margin-top: _s.step(4);
	}
}

.s-wysiwyg--image {
	.c-blog-image {
		margin: _s.step(4) 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
