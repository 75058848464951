// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Root
// =============================================================================
.c-usps {
	margin-top: _s.step(5);
	margin-bottom: _s.step(3);
	padding: _s.step(4) 0;

	background-color: _c.set("background light");

	@include _b.at("viewport-9") {
		margin-top: _s.step(8);
		margin-bottom: _s.step(4);
		padding: _s.step(6) 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-usps__icon {
	width: 100%;
	max-width: _s.rem(60px, false);
	height: 100%;
	max-height: _s.rem(60px, false);
}


.c-usps__title {
	@include _t.set("highlight");

	margin: _s.step(2) 0 _s.step(1);

	color: _c.set("text highlight");
}
