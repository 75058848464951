// =============================================================================
// Definition
// =============================================================================

/**
 * The z-indexes are contextually based on the different layers within a site.
 * If a custom z-index is applied, it is based upon one of the items by adding
 * (+) or subtracting (-) a number.
 */

$foreground: 9999;
$modal: 9000;
$overlay: 8000;
$dropdown: 7000;
$header: 6000;
$nav: 5000;
$footer: 4000;
$base: 10;
$background: -1;
