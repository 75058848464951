// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Font families
// =============================================================================
$font-family-primary: ("Work Sans", "Arial", sans-serif);
$font-family-secondary: ("Noto Sans", "Arial", sans-serif);

// =============================================================================
// Font weights
// =============================================================================
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
$font-weight-bold: 700;
$font-weight-semi-bold: 600; // semi-bold/demi-bold
$font-weight-medium: 500;
$font-weight-normal: 400; // normal/regular
$font-weight-light: 300;

// =============================================================================
// Font styles
// =============================================================================
$styles: (
	body: (
		font-family: $font-family-secondary,
		font-size: _s.$base-font-size,
		line-height: _s.rem(24px)
	),
	h1: (
		font-family: $font-family-primary,
		font-size: _s.rem(40px),
		letter-spacing: _s.rem(-2px, false),
		line-height: 1.2,
		font-weight: $font-weight-light,
		viewport-9: (
			font-size: _s.rem(66px, false),
			letter-spacing: _s.rem(-3px, false),
		)
	),
	h2: (
		font-family: $font-family-primary,
		font-size: _s.rem(34px, false),
		letter-spacing: _s.rem(-2px, false),
		line-height: 1.2,
		font-weight: $font-weight-light,
		viewport-9: (
			font-size: _s.rem(48px)
		)
	),
	h3: (
		font-family: $font-family-primary,
		font-size: _s.rem(27px, false),
		letter-spacing: _s.rem(-1px, false),
		line-height: 1.2,
		font-weight: $font-weight-normal,
		viewport-9: (
			font-size: _s.rem(36px, false),
			letter-spacing: _s.rem(-2px, false),
		)
	),
	h4: (
		font-family: $font-family-primary,
		font-size: _s.rem(21px, false),
		line-height: 1.2,
		font-weight: $font-weight-medium,
		viewport-9: (
			font-size: _s.rem(24px),
			letter-spacing: _s.rem(-1px, false)
		)
	),
	h5: (
		font-family: $font-family-primary,
		font-size: _s.rem(18px, false),
		line-height: 1.2,
		font-weight: $font-weight-medium,
		viewport-9: (
			font-size: _s.rem(21px, false),
			letter-spacing: _s.rem(-1px, false),
		)
	),
	h6: (
		font-family: $font-family-primary,
		font-size: _s.rem(13px, false),
		line-height: 1.5,
		font-weight: $font-weight-semi-bold,
		letter-spacing: _s.rem(2px, false),
		text-transform: uppercase
	),
	highlight: (
		font-family: $font-family-primary,
		font-size: _s.rem(16px),
		line-height: 1.5,
		font-weight: $font-weight-semi-bold,
		letter-spacing: _s.rem(2px, false),
		text-transform: uppercase
	),
	p: (
		font-family: $font-family-secondary,
		font-size: _s.rem(16px),
		line-height: _s.rem(24px)
	),
	small: (
		font-family: $font-family-secondary,
		font-size: _s.rem(14px, false),
		line-height: _s.rem(21px, false)
	),
	medium: (
		font-family: $font-family-secondary,
		font-weight: $font-weight-light,
		font-size: _s.rem(16px),
		line-height: _s.rem(26px, false),
		viewport-7: (
			font-size: _s.rem(18px, false),
			line-height: _s.rem(30px, false),
		)
	),
	large: (
		font-family: $font-family-secondary,
		font-weight: $font-weight-light,
		font-size: _s.rem(16px),
		line-height: _s.rem(24px),
		viewport-7: (
			font-size: _s.rem(20px, false),
			line-height: _s.rem(28px, false),
		)
	),
	description: (
		font-family: $font-family-primary,
		font-weight: $font-weight-light,
		font-size: _s.rem(16px),
		line-height: _s.rem(24px),
		viewport-7: (
			font-size: _s.rem(20px, false),
			line-height: _s.rem(28px, false),
		)
	),
	button: (
		font-family: $font-family-primary,
		font-weight: $font-weight-medium,
		font-size: _s.rem(16px),
		line-height: _s.rem(24px),
		letter-spacing: _s.rem(-1px, false)
	),
	badge: (
		font-family: $font-family-secondary,
		font-weight: $font-weight-normal,
		font-size: _s.rem(11px, false),
		line-height: _s.rem(16px),
	),
	input: (
		font-family: $font-family-secondary,
		font-size: _s.rem(16px),
		line-height: _s.rem(24px)
	),
	label: (
		font-family: $font-family-secondary,
		font-weight: $font-weight-normal,
		font-size: _s.rem(12px, false),
		line-height: _s.rem(18px, false),
		text-transform: uppercase
	)
);
