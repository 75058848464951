// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "settings/borders" as borders;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
.c-card {
	padding: _s.step(6) _s.step(3);

	text-align: center;

	background-color: _c.set("background light");
	background-image:
		url("/assets/images/card/square.svg"),
		url("/assets/images/card/square.svg"),
		url("/assets/images/card/square.svg"),
		url("/assets/images/card/square.svg");
	background-position:
		top _s.step(2) left _s.step(2),
		top _s.step(2) right _s.step(2),
		bottom _s.step(2) left _s.step(2),
		bottom _s.step(2) right _s.step(2);
	background-repeat: no-repeat;
	border: borders.$base;

	@include _b.at("viewport-7") {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		padding-right: _s.step(5);
		padding-left: _s.step(5);

		text-align: left;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-card__title {
	@include _t.set("h4");
}

.c-card__description {
	margin-top: _s.step(2);
}

.c-card__link-container {
	margin-top: auto;
	padding-top: _s.step(3);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-card--secondary {
	background-color: _c.set("background dark");

	.c-card__title {
		color: _c.set("text paragraph-inverse");
	}

	.c-card__description {
		color: _c.set("text title-inverse");
	}
}

.c-card__link--full-width {

	@include _b.at("viewport-7") {
		width: 100%;
	}
}
