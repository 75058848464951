// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
// .c-member {}

// =============================================================================
// Elements
// =============================================================================
.c-member__header-container {
	display: flex;
	align-items: center;
	margin: _s.rem(-8px);

	@include _b.at("viewport-9") {
		flex-direction: column;
		align-items: flex-start;
	}
}

.c-member__image {
	width: _s.step(11);
	margin: _s.rem(8px);

	@include _b.at("viewport-9") {
		width: _s.step(20);
	}
}

.c-member__header {
	margin: _s.rem(8px);
}

.c-member__title {
	@include _t.set("h5");

	color: _c.set("text title-inverse");
}

.c-member__subtitle {
	@include _t.set("h6");

	margin-top: _s.step(1);

	color: _c.set("text highlight");
}

.c-member__description {
	margin-top: _s.step(2);

	color: _c.set("text paragraph-inverse");
}

.c-member__tags-container {
	margin-top: _s.step(2);
}

.c-member__tags {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin: _s.step(1) _s.rem(-4px, false) _s.rem(-4px, false);
	padding: 0;

	list-style: none;
}

.c-member__tags-item {
	margin: _s.rem(10px, false) _s.rem(4px, false);
}
