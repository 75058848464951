// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Root
// =============================================================================
// .c-filter {}

// =============================================================================
// Elements
// =============================================================================
.c-filter__button {
	@include _t.set("button");

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: _s.step(1) _s.step(2);

	border: none;
	background-color: _c.set("background primary");
	color: _c.set("text dark");

	@include _b.at("viewport-7") {
		display: none;
	}
}

.c-filter__list {
	display: flex;
	flex-wrap: wrap;

	list-style: none;

	@include _b.to("viewport-7") {
		flex-direction: column;
		width: 100%;
		height: 0;
		overflow: hidden;

		background-color: _c.set("background light");
	}
}

.c-filter__item {
	margin: _s.step(1) 0;

	@include _b.at("viewport-7") {
		margin: _s.step(3) _s.step(4) 0 0;
	}
}

.c-filter__link {
	@include _t.set("button");

	position: relative;

	padding-bottom: _s.step(1);

	text-decoration: none;

	color: _c.set("text title");

	transition: color 0.25s ease-in-out;

	&::before {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: _s.rem(2px, false);

		background-color: _c.set("border secondary");

		transform: scaleX(0);
		transition: transform 0.3s ease-in-out;
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-filter__link--highlighted {
	color: _c.set("brand secondary");

	&::before {
		transform: scaleX(1);
	}
}

// =============================================================================
// States
// =============================================================================
.c-filter {

	&.is-open {

		.c-filter__list {
			height: auto;
			padding: _s.step(1) _s.step(2);
		}
	}
}

.c-filter__link {

	&:hover {
		color: _c.set("brand secondary");

		&::before {
			transform: scaleX(1);
		}
	}
}
