// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	width: _s.rem(40px);
	height: _s.rem(40px);

	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: inherit;
		height: inherit;

		fill: currentColor;
	}
}
