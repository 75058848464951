// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;
@use "settings/box-shadow" as boxShadow;

// =============================================================================
// Root
// =============================================================================
.c-resource-preview {
	display: flex;
	width: 100%;
	height: 100%;
}

// =============================================================================
// Elements
// =============================================================================
.c-resource-preview__link {
	position: relative;
	z-index: zIndex.$base + 1;

	width: 100%;

	text-decoration: none;

	background-color: _c.set("background light");

	transition: background-color 0.3s ease-in-out;

	@include _b.at("viewport-4") {
		padding: _s.step(3) _s.step(3) _s.step(3.5) _s.step(3);
	}

	&:hover {
		background-color: _c.set("background light-shade");
	}
}

.c-resource-preview__content {
	@include _b.to("viewport-4") {
		padding: 0 _s.step(2) _s.step(3) _s.step(2);
	}
}

.c-resource-preview__category {
	@include _t.set("h6");

	margin-top: _s.step(2);

	color: _c.set("text highlight");
}

.c-resource-preview__image {
	width: 100%;
	height: auto;
}

.c-resource-preview__title {
	@include _t.set("h5");

	margin-top: _s.step(1);

	color: _c.set("text paragraph");
}

.c-resource-preview__description {
	margin-top: _s.step(1);

	color: _c.set("text light");
}
