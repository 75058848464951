// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
// .c-avatar {}

// =============================================================================
// Elements
// =============================================================================
.c-avatar__image {
	width: _s.rem(24px);
	height: _s.rem(24px);
	margin-right: _s.step(1);

	border-radius: 50%;

	@include _b.at("viewport-7") {
		width: _s.rem(40px);
		height: _s.rem(40px);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-avatar__image--small {
	width: _s.rem(24px);
	height: _s.rem(24px);
}
