// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Variables
// =============================================================================
$_ordered-value-number-size: _s.rem(30px, false);
$_ordered-value-border-size: _s.rem(4px, false);
$_ordered-value-title-spacing: _s.rem(8px);

// =============================================================================
// Root
// =============================================================================
// .c-ordered-value {}

// =============================================================================
// Elemens
// =============================================================================
.c-ordered-value__header {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.c-ordered-value__number {
	@include _t.set("h6");

	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 $_ordered-value-number-size;
	width: $_ordered-value-number-size;
	height: $_ordered-value-number-size;
	// Some negative margin to align the number and title
	margin-top: _s.rem(-4px, false);
	// Some padding left to align the number nicely in the middle
	padding-left: _s.rem(2px, false);

	text-align: center;

	background-color: _c.set("background primary");
	border-radius: 100%;
}

.c-ordered-value__title {
	@include _t.set("h4");

	margin-left: $_ordered-value-title-spacing;
}

.c-ordered-value__description {
	margin-top: _s.step(1);
	margin-left:
		($_ordered-value-number-size / 2)
		- ($_ordered-value-border-size / 2);
	padding-left:
		($_ordered-value-number-size / 2)
		- ($_ordered-value-border-size / 2)
		+ $_ordered-value-title-spacing;

	border-left: solid _c.set("border base") $_ordered-value-border-size;

	@include _b.at("viewport-7") {
		margin-top: _s.step(2);
		margin-left: $_ordered-value-number-size + $_ordered-value-title-spacing;
	}
}
