// =============================================================================
// SR only
// =============================================================================

/**
 * Visually hide elements accssibly
 */

.u-sr-only {
	position: absolute;

	width: 1px;
	height: 1px;
	overflow: hidden;

	white-space: nowrap;
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
}
