// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-hamburger {
	position: relative;

	padding: 0;

	appearance: none;
	border: none;
	background-color: transparent;
}

// =============================================================================
// Elements
// =============================================================================
.c-hamburger__icon {
	width: _s.rem(40px);
	height: _s.rem(40px);

	color: _c.set("text dark");
}

// =============================================================================
// Modifiers
// =============================================================================
.c-hamburger--inverted {

	.c-hamburger__icon {
		color: _c.set("text white");
	}
}

.c-hamburger__icon--close {
	display: none;
}

// =============================================================================
// States
// =============================================================================
.c-hamburger {

	&.is-active {

		.c-hamburger__icon--open {
			display: none;
		}

		.c-hamburger__icon--close {
			display: block;
		}
	}
}
