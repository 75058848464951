/**
 * Collection of breakpoints where each name starts with viewport and the
 * hundreth number the breakpoint belongs to. THE ORDER IS VERY IMPORTANT: they
 * should be ordered from lowest to highest.
 */
/**
 * Private colors: HSL based colors. The hue and lightness are reflected in
 * naming. DO NOT USE THESE COLORS OUTSIDE THIS FILE.
 */
/**
 * Public colors: Color system, colors are given a contextual name to reuse
 * within other instances. KEEP THIS MANAGEABLE.
 */
/**
 * Global basis of the font-size to base all other font-sizes upon. This should
 * be the font-size placed on regular text (<p>).
 */
/**
 * Check if the passed string contains the passed characters. Returns false if
 * this is not the case.
 */
/**
 * @author Hugo Giraudel
 * Replace a certain part of a string by another string.
 *
 * https://css-tricks.com/snippets/sass/str-replace-function/
 */
/**
 * Adds extra slashes to special characters. Is usefull for using special
 * characters in classes.
 */
/**
 * Convert a pixel value to a rem value. If the base font size is different than
 * the default browser font-size, then this can be passed through the second
 * parameter.
 */
/**
 * Convert a pixel value to an em value. If the base font size is different than
 * the default browser font-size, then this can be passed through the second
 * parameter.
 */
/**
 * Convert a rem value to a px value. If the base font size is different than
 * the default browser font-size, then this can be passed through the second
 * parameter.
 */
/**
 * Check if the passed list contains the passed item. Returns false if this is
 * not the case.
 */
/**
 * The standard map-merge function only lets you merge 2 maps together. This
 * function makes use of map-merge but merges as much maps together as you want.
 */
/**
 * Reverses the order of a map, is especially useful when working with a
 * mobile-first based breakpoints map.
 */
/**
 * Trims a map to a certain target key. Returns a new map that is trimmed down
 * until the key is met.
 */
/**
 * Retrieve a value associated from a map based on the path passed to it. This
 * path can be recursive to go as deep into the map as necessary.
 */
/**
 * Check if the passed map contains the passed key. Returns false if this is
 * not the case.
 */
/**
 * Generate a map of items where each key in the map is included into the passed
 * list.
 */
/**
 * Generate a list of values where each key in the map is included into the
 * passed list.
 */
/**
 * Based on the keys in the $list, return the keys from the map which have the
 * same value.
 */
/**
 * Power function / exponent operator which accepts positive, negative (integer,
 * float) exponents.
 *
 * https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Pass a pixel value and return a rem value, converted based on the
 * $base-font-size.
 */
/**
 * Calculate the closest step to the passed value in the progression system,
 * based on the passed configuration to snap too.
 */
/**
 * Calculate the root font-size, based on the base font-size of the system and
 * the default browser font-size. Return a percentage.
 */
/**
 * Private functions: not to be used or forwarded by the library. Checks and
 * converts, if the value passed to the breakpoint is usable.
 */
/**
 * Retrieve the color value from the configuration based on the path of keys
 * within the color configuration map.
 */
/**
 * Create a CSS property that is responsive by defining a minimum and maximum
 * value. For example: responsive font-sizes.
 */
/**
 * Reset a button by removing the default styling properties. BUT BE WARNED:
 * Take ACCESSIBILITY into account!
 */
/**
 * Add 3 dots to a text element that goes out of its bounds. This is always
 * limited to one straight line.
 */
/**
 * Only display content to screen readers, hide the element offscreen to keep
 * its functionality and behaviour. DO NOT COMPROMISE ACCESSIBILITY.
 */
/**
 * Used in float layouts where elements are floated to be stacked horizontally.
 * Automatically clear its child elements, so that you don't need to add
 * additional markup.
 */
/**
 * Apply a font-style containing multiple text style properties based on the
 * font-styles configuration. Also looks for the breakpoints defined within to
 * create a responsive font-style.
 */
/**
 * System: https://www.npmjs.com/package/@littlemissrobot/sass-system
 * @use "@littlemissrobot/sass-system" as _;
 *
 * Functions: https://www.npmjs.com/package/@littlemissrobot/sass-functions
 * @use "@littlemissrobot/sass-system/functions" as _f;
 * @use "@littlemissrobot/sass-system" as _; _.f_
 *
 * Breakpoints: https://www.npmjs.com/package/@littlemissrobot/sass-mixins
 * @use "@littlemissrobot/sass-system/mixins" as _m;
 * @use "@littlemissrobot/sass-system" as _; _.m_
 *
 * Breakpoints: https://www.npmjs.com/package/@littlemissrobot/sass-breakpoints
 * @use "@littlemissrobot/sass-system/breakpoints" as _b;
 * @use "@littlemissrobot/sass-system" as _; _.b_
 *
 * Colors: https://www.npmjs.com/package/@littlemissrobot/sass-colors
 * @use "@littlemissrobot/sass-system/colors" as _c;
 * @use "@littlemissrobot/sass-system" as _; _.c_
 *
 * Spacing: https://www.npmjs.com/package/@littlemissrobot/sass-spacing
 * @use "@littlemissrobot/sass-system/spacing" as _s;
 * @use "@littlemissrobot/sass-system" as _; _.s_
 * @use "@littlemissrobot/sass-system" as _; _.s_l_
 * @use "@littlemissrobot/sass-system" as _; _.s_t_
 *
 * Typography: https://www.npmjs.com/package/@littlemissrobot/sass-typography
 * @use "@littlemissrobot/sass-system/typography" as _t;
 * @use "@littlemissrobot/sass-system" as _; _.t_
 */
/**
 * typography: https://www.npmjs.com/package/@littlemissrobot/sass-typography
 * @use "@littlemissrobot/sass-system/typography" as _t;
 * @use "@littlemissrobot/sass-system" as _; _.t_
 */
.u-margin-top\:1 {
  margin-top: 0.5rem;
}

.u-margin-top\:2 {
  margin-top: 1rem;
}

.u-margin-top\:4 {
  margin-top: 2rem;
}

.u-margin-top\:8 {
  margin-top: 4rem;
}

@media screen and (min-width: 45.0625em) {
  .u-margin-top\:12\@at\:viewport-7 {
    margin-top: 6rem;
  }
}

@media screen and (min-width: 62.0625em) {
  .u-margin-top\:4\@at\:viewport-9 {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 45em) {
  .u-margin-top\:6\@to\:viewport-7 {
    margin-top: 3rem;
  }
}

.u-margin-bottom\:1 {
  margin-bottom: 0.5rem;
}

.u-margin-bottom\:4 {
  margin-bottom: 2rem;
}

.u-margin-bottom\:6 {
  margin-bottom: 3rem;
}

@media screen and (min-width: 45.0625em) {
  .u-margin-bottom\:6\@at\:viewport-7 {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 45.0625em) {
  .u-margin-bottom\:12\@at\:viewport-7 {
    margin-bottom: 6rem;
  }
}

@media screen and (min-width: 62.0625em) {
  .u-margin-left\:4\@at\:viewport-9 {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 45em) {
  .u-padding-top\:20\@to\:viewport-7 {
    padding-top: 10rem;
  }
}

@media screen and (max-width: 45em) {
  .u-padding-bottom\:2\@to\:viewport-7 {
    padding-bottom: 1rem;
  }
}

.u-color\:text\:title-inverse {
  color: white;
}

.u-color\:text\:paragraph-inverse {
  color: #e4e7f6;
}

.u-color\:text\:highlight {
  color: #8b99f9;
}

.u-background-color\:background\:white {
  background-color: white;
}

.u-background-color\:background\:light {
  background-color: #fbfbfe;
}

.u-background-color\:background\:primary {
  background-color: #61ffd7;
}

.u-background-color\:background\:dark {
  background-color: #0a0d1f;
}

.u-background-color\:background\:secondary-dark {
  background-color: #252946;
}

.u-background-color\:background\:secondary-dark {
  background-color: #252946;
}

.u-font\:h1 {
  font-family: "Work Sans", "Arial", sans-serif;
  font-size: 2.5rem;
  letter-spacing: -0.125rem;
  line-height: 1.2;
  font-weight: 300;
}
@media screen and (min-width: 62.0625em) {
  .u-font\:h1 {
    font-size: 4.125rem;
    letter-spacing: -0.1875rem;
  }
}

.u-font\:h3 {
  font-family: "Work Sans", "Arial", sans-serif;
  font-size: 1.6875rem;
  letter-spacing: -0.0625rem;
  line-height: 1.2;
  font-weight: 400;
}
@media screen and (min-width: 62.0625em) {
  .u-font\:h3 {
    font-size: 2.25rem;
    letter-spacing: -0.125rem;
  }
}

.u-font\:h4 {
  font-family: "Work Sans", "Arial", sans-serif;
  font-size: 1.3125rem;
  line-height: 1.2;
  font-weight: 500;
}
@media screen and (min-width: 62.0625em) {
  .u-font\:h4 {
    font-size: 1.5rem;
    letter-spacing: -0.0625rem;
  }
}

.u-font\:h6 {
  font-family: "Work Sans", "Arial", sans-serif;
  font-size: 0.8125rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

/**
 * Config file for compiling the @littlemissrobot/sass-utils package. This file
 * should get compiled to scss/libs/utils.css. This file SHOULD NEVER get @use
 * or @imported within the main scss file.
 *
 * https://www.npmjs.com/package/@littlemissrobot/sass-utils
 */
.u-hide {
  display: none;
}
@media screen and (min-width: 45.0625em) {
  .u-hide\@at\:viewport-7 {
    display: none;
  }
}
@media screen and (max-width: 45em) {
  .u-hide\@to\:viewport-7 {
    display: none;
  }
}

.u-text-align\:center {
  text-align: center;
}

.u-text-align\:left {
  text-align: left;
}

.u-fill-height {
  height: 100%;
}

.u-fill-width {
  width: 100%;
}

.u-width\:50\% {
  width: 50%;
}

.u-width\:70\% {
  width: 70%;
}