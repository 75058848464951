// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
// .c-author {}

// =============================================================================
// Elements
// =============================================================================
.c-author__avatar {
	margin-right: _s.step(2);
}

.mobile-content-item {
	margin-top: _s.step(2);

	@include _b.at("viewport-4") {
		display: none;
	}
}

.desktop-content-item {
	@include _b.to("viewport-4") {
		display: none;
	}
}

.c-author__separator {
	width: _s.rem(4px, false);
	height: _s.rem(4px, false);
	margin-right: _s.step(2);

	background-color: _c.set("background dark");
	border-radius: 50%;
}

.c-author__separator_left {
	width: _s.rem(4px, false);
	height: _s.rem(4px, false);
	margin-left: _s.step(3);

	background-color: _c.set("background dark");
	border-radius: 50%;
}

.c-author__description {
	@include _t.set("small");

	margin-top: _s.step(2);

	color: _c.set("text light");
}
