// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
:root {
	// Set base font-size on the root in percentage.
	@include _s.root();
}

body {
	@include _t.set("p");

	color: _c.set("text paragraph");
	background-color: _c.set("background white");
}
