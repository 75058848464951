// =============================================================================
// Page
// =============================================================================

/**
 * Extension for normalize.scss and acts as a reset. It always sits on top of
 * the normalize.scss file. Logics based off:
 *
 * https://github.com/twbs/bootstrap/blob/master/scss/_reboot.scss
 */

/**
 * Change default `box-sizing: content-box` in order for the width not to get
 * affected by `padding` or `border`.
 */

*,
*::before,
*::after {
	box-sizing: border-box;
}

/**
 * 1. :root selector equalizes `<html>`, but has higher specificity to not have
 *    it easily overwritten.
 * 2. Ensure the page always fills up the entire height of the viewport.
 * 3. Force vertical scrollbars to always be visible to prevent awkward jumps
 *    when navigating between pages that do/do not have content to produce
 *    scrollbars naturally.
 */

:root { // 1
	min-height: 100%; // 2
	overflow-y: scroll; // 3

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**
 * 1. Disable horizontal scrollbars when content is larger than viewport
 * 2. Set behaviour when scrolling by navigatio or scrolling API's
 * 3. Browser focus on rendering speed over legibility and geometric precision.
 */

body {
	max-width: 100%; // 1
	scroll-behavior: smooth; // 2

	text-rendering: optimizeSpeed; // 3
}
