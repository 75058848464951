// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-socials {
	display: flex;
	margin: _s.rem(-8px);
	padding: none;

	list-style: none;
}

// =============================================================================
// Elements
// =============================================================================
.c-socials__item {
	margin: _s.rem(8px);
}

.c-socials__link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: _s.rem(28px, false);
	height: _s.rem(28px, false);

	color: _c.set("text white");
	border: solid _s.rem(1px, false) rgba(_c.set("text white"), 0.2);
	border-radius: 100%;

	transition: border-color 0.3s ease-in-out;

	&:hover {
		border-color: _c.set("border primary");

		.c-socials__icon {
			color: _c.set("border primary");
		}
	}
}

.c-socials__icon {
	width: _s.rem(12px, false);
	height: _s.rem(12px, false);

	transition: color 0.3s ease-in-out;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-socials__inverted {

	.c-socials__link {
		color: _c.set("text paragraph");
		border: solid _s.rem(1px, false) rgba(_c.set("text paragraph"), 0.2);

		transition: border-color 0.3s ease-in-out;

		&:hover {
			border-color: _c.set("border primary");

			.c-socials__icon {
				color: _c.set("border primary");
			}
		}
	}
}
