// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
.c-cases {
	position: relative;

	background-color: _c.set("background dark");
	color: _c.set("text paragraph-inverse");
}

// =============================================================================
// Elements
// =============================================================================
.c-cases__container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-bottom: _s.step(16);
}

.c-cases__title {
	grid-column: 1/13;
	grid-row: 1/2;
	margin-bottom: _s.step(5);

	@include _b.at(viewport-9) {
		grid-column: 1/8;
	}
}

.c-cases__cta {
	display: flex;
	grid-column: 1/13;
	grid-row: 3/4;
	justify-content: center;
	align-items: flex-end;

	@include _b.to(viewport-9) {
		margin-top: _s.step(5);
	}

	@include _b.at(viewport-9) {
		grid-column: 8/13;
		grid-row: 1/2;
		justify-content: flex-end;
		margin-bottom: _s.step(5.5);
	}
}

.c-cases__cases {
	display: grid;
	grid-column: 1/13;
	grid-row: 2/3;
	gap: _s.step(4);

	@include _b.at(viewport-7) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include _b.at(viewport-9) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.c-cases__link {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	text-decoration: none;

	color: _c.set("text paragraph-inverse");
}

.c-cases__link--span {
	@include _b.at(viewport-7) {
		grid-column: 1/3;
	}

	@include _b.at(viewport-9) {
		grid-column: unset;
	}
}

.c-cases__image-container {
	width: 100%;
	height: _s.rem(260px, false);
	overflow: hidden;
	margin-bottom: _s.step(3);
}

.c-cases__image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;

	transition: transform 0.25s ease-in-out;
}

.c-cases__button {
	margin-top: _s.step(2);

	@include _b.at(viewport-9) {
		margin-top: _s.step(3);
	}
}

// =============================================================================
// States
// =============================================================================
.c-cases__link {

	&:hover {

		.c-cases__image {
			transform: scale(1.05);
		}
	}
}
