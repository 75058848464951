// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Root
// =============================================================================
.c-top-bar {
	width: 100%;
	padding: _s.step(1) _s.step(2);

	text-align: center;

	color: _c.set("text white");
	background-color: _c.set("background secondary-dark");

	p {
		@include _t.set(small);
	}

	a {
		color: _c.set("text white");
	}
}
