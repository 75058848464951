// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Root
// =============================================================================
.c-video-embed {
	margin-top: _s.step(4);
}

// =============================================================================
// Root
// =============================================================================
.c-video-embed__header {
	margin-bottom: _s.step(2);
}

