// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/colors" as _c;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-youtube-embed {
	margin-top: _s.step(4);
}

// =============================================================================
// Elements
// =============================================================================
.c-youtube-embed__header {
	margin-bottom: _s.step(2);
}

.c-youtube-embed__video-container {
	position: relative;

	overflow: hidden;
	padding-bottom: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}

.c-youtube-embed__thumbnail-image {
	width: 100%;
	height: auto;
}

.c-youtube-embed__thumbnail {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: _c.set("background dark");

	&,
	.c-youtube-embed__thumbnail-image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: zIndex.$base;

		margin: auto;
	}
}

.c-youtube-embed__button {
	position: relative;
	z-index: zIndex.$base + 1;
}

// =============================================================================
// States
// =============================================================================
.c-youtube-embed__thumbnail {
	&.is-hidden {
		display: none;
	}
}
