// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "./config/typography" as typography;

// =============================================================================
// Root
// =============================================================================
.c-home-hero {
	display: flex;
	align-items: stretch;
	height: 100%;

	/**
	 * Try to keep the hero padding consistent troughout the site and use
	 * the same padding top as the default hero.
	 */
	padding-top: _s.step(4);
	padding-bottom: _s.step(3);

	text-align: center;

	background-color: _c.set("background dark");
	color: _c.set("text white");

	@include _b.at("viewport-9") {
		padding-top: 0;
		padding-bottom: 0;

		text-align: left;
	}
}

// =============================================================================
// Elements
// =============================================================================

.main--container {
	@include _b.at("viewport-9") {
		margin-top: 5vh;
		margin-bottom: 20px;
	}
}

.c-home-hero__title {
	width: 100%;
	margin-top: _s.step(3);

	font-size: 2.3rem;

	@include _b.at("viewport-9") {
		margin-top: _s.step(1);
	}

	@include _b.at("viewport-7") {
		font-size: 2.3rem;
	}

	@include _b.at("viewport-12") {
		font-size: 3rem;
	}
}

.c-home-hero__tagline {
	width: 100%;

	font-size: 0.7rem;

	@include _b.at("viewport-12") {
		font-size: 0.81rem;
	}
}

.c-home-hero__description {
	width: 100%;
	margin-top: _s.step(1);

	@include _b.at("viewport-9") {
		margin-top: _s.step(1);
	}
}

.c-home-hero__buttons {
	display: flex;
	gap: _s.step(5);

	flex-wrap: wrap;
	margin-top: 2vh;

	@include _b.at("viewport-9") {
		margin-top: _s.step(4);
	}
}

.c-home-hero__cta {
	width: 100%;

	@include _b.at("viewport-7") {
		width: auto;
		min-width: _s.rem(200px);
	}
}

.c-home-hero__clients {
	width: 100%;
	padding: _s.step(2) _s.step(2) 0;

	@include _b.at("viewport-9") {
		padding: _s.step(6) 0 0;
	}

	@include _b.at("viewport-12") {
		padding-top: _s.step(6);
	}
}

.c-home-hero__clients-title,
.c-home-hero__tagline {
	@include _t.set("h6");

	color: _c.set("text highlight");
}

.c-home-hero__clients-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: _s.step(3) auto;
	padding: none;

	list-style: none;

	gap: _s.step(4);

	@include _b.at("viewport-7") {
		gap: _s.step(5);
	}

	@include _b.at("viewport-9") {
		justify-content: flex-start;
	}
}

.c-home-hero__client-image {
	object-fit: contain;
	width: 100%;
	max-width: _s.rem(64px);
	height: auto;
	max-height: _s.rem(44px, false);

	@include _b.at("viewport-7") {
		max-width: _s.rem(88px);
		max-height: _s.rem(52px, false);
	}
}

.c-home-hero__animation-container {
	@include _b.at("viewport-9") {
		margin-top: -150px;

		transform: translateY(50px);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-home-hero__content\:desktop {
	display: none;

	@include _b.at("viewport-9") {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		align-self: center;
		margin-bottom: _s.step(4);
	}
}

.c-home-hero__content\:mobile {
	margin: _s.step(4) 0;

	@include _b.at("viewport-9") {
		display: none;
	}
}

.c-home-video__content\:mobile {
	@include _b.at("viewport-9") {
		display: none;
	}
}
