// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "settings/z-index" as zIndex;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
.c-decorated-image {
	position: relative;

	display: flex;
	justify-content: flex-end;
	padding: _s.step(2) _s.step(2) _s.step(4);

	&::before,
	&::after {
		content: "";

		position: absolute;

		height: 80%;
	}

	&::before {
		bottom: 0;
		left: 0;
		z-index: zIndex.$base - 1;

		width: 80%;

		background-image: url("/assets/images/decorated-image/dot-pattern-white.svg");
		background-repeat: repeat;
		background-position: bottom left;
		background-size: _s.rem(32px);
	}

	&::after {
		top: 0;
		right: 0;

		width: 70%;

		background-image:
			linear-gradient(
				to top right,
				transparent 50%,
				rgba(_c.set("brand primary-dark"), 0.4)
			);
	}

	@include _b.at("viewport-7") {
		padding: _s.step(3);

		&::before {
			left: 0;
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-decorated-image__source {
	z-index: zIndex.$base;

	width: 100%;
	height: 100%;
}

// =============================================================================
// Modifiers
// =============================================================================
/**
 * A variation with slightly different positioning of the gradient. On mobile
 * devices, the decorations expand outside the container.
 */
.c-decorated-image--variation1 {
	padding: 0;

	&::before {
		left: _s.rem(-16px);

		background-image: url("/assets/images/decorated-image/dot-pattern-purple.svg");
	}

	&::after {
		top: _s.rem(-16px);
		right: _s.rem(-16px);
	}

	@include _b.at("viewport-7") {
		padding: _s.step(3) _s.step(5);

		&::before {
			bottom: _s.rem(-8px);
			left: 0;
		}

		&::after {
			top: 0;
			right: _s.step(5) * 2;
		}
	}
}

.c-decorated-image--light {

	&::after {
		top: 0;
		right: 0;

		width: 70%;

		background-image:
			linear-gradient(
				to top right,
				transparent 50%,
				rgba(_c.set("brand primary"), 0.4)
			);
	}
}

.c-decorated-image--size-only {
	padding: 0;

	&::before,
	&::after {
		display: none;
	}

	@include _b.at("viewport-7") {
		padding: _s.step(3);
	}
}
