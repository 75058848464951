/*** HubSpot - techwolf-theme/css/elements/_forms.css ***/

form {
	position: relative;
}

/* Form Title */

.form-title {
	margin-bottom: 2.5rem;
}

/* Fields */

.hs-form-field {
	margin-bottom: 2.5rem;
}

/* Labels */

form label {
	display: block;

	font-size: 1rem;
}

/* Help text */

form legend {
	font-size: 0.875rem;
}

/* Inputs */

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="tel"],
form input[type="number"],
form input[type="file"],
form select,
form textarea {
	display: inline-block;
	width: 100%;
	margin-top: 0.25rem;
	padding: 0 !important;

	outline-offset: 0.25rem;
	font-size: 1rem;
}

form textarea,
form input[type="file"],
form select {
	padding: 0.5rem !important;
}

form .hs_date_picker input[type="text"],
form select,
form textarea {
	height: 3rem;
	padding: 0.5rem;

	appearance: none;
}

form textarea {
	resize: vertical;
}

form fieldset {
	max-width: 100% !important;
}

.form-columns-1 .hs-input,
.form-columns-2 .hs-input {
	width: 100% !important;
}

@media (min-width: 480px) {
	.form-columns-2 .hs-form-field {
		width: 45% !important;
	}

	.form-columns-2 .hs-form-field:first-child {
		float: left !important;
	}

	.form-columns-2 .hs-form-field:last-child {
		float: right !important;
	}
}

/* Inputs - checkbox/radio */

form .inputs-list {
	margin: 0;
	padding: 0;

	list-style: none;
}

form .inputs-list > li {
	display: block;
	margin: 0.5rem 0;
}

form .inputs-list input,
form .inputs-list span {
	vertical-align: top;
}

form input[type="checkbox"],
form input[type="radio"] {
	margin-right: 0.25rem;

	cursor: pointer;
}

/* Inputs - Checkboxes / Radio Select */

ul.inputs-list:not(.hs-error-msgs) li {
	padding: 0.25rem 0;
}

/* Inputs - Custom Checkbox and Radio Buttons */

li.hs-form-checkbox label,
li.hs-form-booleancheckbox label,
li.hs-form-radio label {
	position: relative;
}

li.hs-form-checkbox label span:not(.hs-form-required),
li.hs-form-booleancheckbox label span:not(.hs-form-required),
li.hs-form-radio label span:not(.hs-form-required),
.legal-consent-container li.hs-form-booleancheckbox label > span:not(.hs-form-required) {
	display: inline-block;
	margin-left: 1.5rem !important;
}

/* Inputs - Custom Checkbox: Hide the original checkbox */
li.hs-form-checkbox label input,
li.hs-form-booleancheckbox label input,
li.hs-form-radio label input {
	position: absolute;
	top: 0;
	left: 0;

	width: 1rem;
	height: 1rem;

	opacity: 0;
}

/* Inputs - Custom Checkbox: Add new checkbox */
li.hs-form-checkbox label span:not(.hs-form-required)::before,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::before,
li.hs-form-radio label span:not(.hs-form-required)::before {
	content: "";

	position: absolute;
	top: 0.25rem;
	left: 0;

	width: 1rem;
	height: 1rem;

	border-radius: 0;
}

li.hs-form-checkbox label span:not(.hs-form-required)::before,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::before {
	border-radius: 0;
}

li.hs-form-radio label span:not(.hs-form-required)::before {
	border-radius: 50%;
}

/* Inputs - Custom Checkbox: Style new checked item */
li.hs-form-radio label span:not(.hs-form-required)::after {
	content: "";

	position: absolute;
	top: 0.25rem;
	left: 0.25rem;

	width: calc(1rem - 0.5rem);
	height: calc(1rem - 0.5rem);

	opacity: 0;
}

li.hs-form-checkbox label span:not(.hs-form-required)::after,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::after {
	content: "";

	position: absolute;
	top: 0.3rem;
	left: 0.4rem;

	width: 0.25rem;
	height: 0.75rem;

	background: none;
	border-width: 2px;
	border-style: solid;
	border-top: 0;
	border-left: 0;
	opacity: 0;

	transform: rotate(45deg);
}

li.hs-form-checkbox label span:not(.hs-form-required)::after,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::after {
	border-radius: 0;
}

li.hs-form-radio label span:not(.hs-form-required)::after {
	border-radius: 50%;
}

/* Inputs - Custom Checkbox: Show when checked */
li.hs-form-checkbox label input:checked + span::after,
li.hs-form-booleancheckbox label input:checked + span::after,
li.hs-form-radio label input:checked + span::after {
	opacity: 1;
}

/* Inputs - Custom Checkbox: Style when focused */
li.hs-form-checkbox label input:focus + span::after,
li.hs-form-booleancheckbox label input:focus + span::after,
li.hs-form-radio label input:focus + span::after {
	outline: 3px solid transparent; /* For Windows high contrast mode. */
}

/* Inputs - date picker */

.hs-dateinput {
	position: relative;
}

.hs-dateinput::before {
	content: "\01F4C5";

	position: absolute;
	top: calc(50% + 0.75rem); /* Accounts for input top margin */
	right: 1rem;

	transform: translateY(-50%);
}

.fn-date-picker .pika-table thead th {
	color: #ffffff;
}

.fn-date-picker td.is-selected .pika-button {
	border-radius: 0;
	box-shadow: none;
}

.fn-date-picker td .pika-button:hover,
.fn-date-picker td .pika-button:focus {
	border-radius: 0 !important;
	color: #ffffff;
}

/* Inputs - file picker */

form input[type="file"] {
	padding: initial;

	border: initial;
	background-color: transparent;
}

/* Headings and text */

form .hs-richtext,
form .hs-richtext p {
	margin: 0 0 1.4rem;

	font-size: 0.875rem;
}

form .hs-richtext img {
	max-width: 100% !important;
}

/* GDPR */

.legal-consent-container .hs-form-booleancheckbox-display > span,
.legal-consent-container .hs-form-booleancheckbox-display > span p {
	font-size: 0.875rem;
}

/* Validation */

.hs-form-required {
	margin-left: 0.25rem;

	color: #bf1622;
}

.hs-input.invalid.error {
	border-color: #bf1622;
}

.hs-error-msg {
	margin-top: 0.25rem;

	color: #bf1622;
}

/* Submit button */

form input[type="submit"],
form .hs-button {
	display: inline-block;

	text-align: center;
	white-space: normal;

	transition: all 0.15s linear;

	cursor: pointer;
}

/* Captcha */

.grecaptcha-badge {
	margin-top: 1.5rem;
}

@media (min-width: 1140px) {
	.grecaptcha-badge {
		position: absolute;
		right: 0;
		bottom: 0;

		margin: 0;
	}
}

/*** HubSpot - techwolf-theme/css/theme-overrides.css (Forms) ***/

form,
.submitted-message {
	font-family: "Noto Sans", sans-serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
}

.widget-type-form {
	padding: 0rem;

	border-radius: 0px;
	border-top: 0px solid rgba(255, 255, 255, 0%);
	border-right: 0px solid rgba(255, 255, 255, 0%);
	border-bottom: 0px solid rgba(255, 255, 255, 0%);
	border-left: 0px none rgba(255, 255, 255, 0%);
	background-color: rgba(255, 255, 255, 0);
}

/* Form Title */

.form-title {
	background-color: #ffffff;
	color: #252946;
}

/* Labels */

form label {
	font-family: Work Sans;

	color: #8aa2ad;
}

/* Help Text */

form legend {
	color: #8aa2ad;
}

/* Inputs */

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="tel"],
form input[type="number"],
form input[type="file"],
form select,
form textarea {
	border-top: 0px solid rgba(10, 13, 31, 0%);
	border-right: 0px solid rgba(10, 13, 31, 0%);
	border-bottom: 2px solid #0a0d1f;
	border-left: 0px solid rgba(10, 13, 31, 0%);
	background-color: rgba(255, 255, 255, 1);
	color: #252946;
	border-radius: 0px;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="tel"],
form input[type="number"],
form input[type="file"],
form select,
form textarea {
	border-top: 0px solid rgba(10, 13, 31, 0%);
	border-right: 0px solid rgba(10, 13, 31, 0%);
	border-bottom: 2px solid #0a0d1f;
	border-left: 0px solid rgba(10, 13, 31, 0%);
	background-color: rgba(255, 255, 255, 1);
	color: #252946;
	border-radius: 0px;
}

form .hs_date_picker input[type="text"],
form select,
form textarea {
	border-color: #0a0d1f;
	border-style: solid;
	border-width: 2px;
}

/* Form Placeholder Text */

::-webkit-input-placeholder {
	color: #252946;
}

::-moz-placeholder {
	color: #252946;
}

:-ms-input-placeholder {
	color: #252946;
}

::placeholder {
	color: #252946;
}

/* Inputs - Checkboxes / Radio Select */

li.hs-form-checkbox label span:not(.hs-form-required)::before,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::before,
li.hs-form-radio label span:not(.hs-form-required)::before {
	border-color: #0a0d1f;
	border-style: solid;
	border-width: 2px;
	background-color: rgba(255, 255, 255, 1);
}

li.hs-form-radio label span::after {
	background-color: #8997f9;
}

li.hs-form-checkbox label span:not(.hs-form-required)::after,
li.hs-form-booleancheckbox label span:not(.hs-form-required)::after {
	border-color: #8997f9;
}

/* Inputs - Date Picker */

.hs-fieldtype-date .input .hs-dateinput::before {
	color: #252946;
}

.fn-date-picker td.is-selected .pika-button {
	background: #63ffd9;
}

.fn-date-picker td .pika-button:hover {
	background-color: #63ffd9 !important;
}

.fn-date-picker td.is-today .pika-button {
	color: #63ffd9;
}

/* Submit Button */

form input[type="submit"],
form .hs-button {
	padding-top: 0.875rem;
	padding-right: 1.5rem;
	padding-bottom: 0.875rem;
	padding-left: 1.5rem;

	font-family: "Work Sans", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: -0.0625rem;
	text-transform: none;
	text-decoration: none;

	border-radius: 0px;
	border: 1px solid #63ffd9;
	background-color: rgba(99, 255, 217, 1);
	color: #0a0d1f;
}

form input[type="submit"]:hover,
form input[type="submit"]:focus,
form .hs-button:hover,
form .hs-button:focus {
	font-family: "Work Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	text-decoration: none;

	background-color: rgba(108, 230, 224, 1);
	border-color: #6ce6e0;
	color: #252946;
}

form input[type="submit"]:active,
form .hs-button:active {
	font-family: "Work Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	text-decoration: none;

	background-color: rgba(139, 255, 255, 1);
	border-color: #8bffff;
}

/* Recaptcha (Adjust positioning based on form spacing) */

@media (min-width: 1140px) {
	.hs-recaptcha {
		right: 0rem;
		bottom: 0rem;
	}
}

.hs-recaptcha {
	margin: 0;
}
