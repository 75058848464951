// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-article-preview {
	position: relative;

	@include _b.at("viewport-7") {

		&::before,
		&::after {
			content: "";

			position: absolute;
			top: _s.rem(8px);
			right: 0;

			width: _s.rem(4px, false);
			height: _s.rem(48px);

			transform-origin: 0 0;
			transition: transform 0.2s ease-in;
		}

		&::before {
			background-color: _c.set("border base");
		}

		&::after {
			background-color: _c.set("border primary");

			transform: scaleY(0);
		}

		&:hover {

			&::after {
				transform: scaleY(1);
			}
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-article-preview__container {
	position: relative;

	display: flex;
	flex-direction: row-reverse;
	margin: _s.rem(-8px);

	@include _b.at("viewport-3") {
		flex-direction: column;
	}

	@include _b.at("viewport-7") {
		flex-direction: row-reverse;
		margin: _s.rem(-16px);
		padding-right: _s.step(3);
	}
}

.c-article-preview__link {
	position: absolute;
	top: 0;
	left: 0;
	z-index: zIndex.$base + 1;

	width: 100%;
	height: 100%;

	text-indent: -9999rem;
}

.c-article-preview__image-container {
	position: relative;

	flex: 0 0 _s.rem(120px, false);
	margin: _s.rem(8px);
	margin-bottom: _s.step(2);

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: zIndex.$background;

		width: calc(100% + #{_s.rem(8px)});
		height: calc(100% + #{_s.rem(8px)});

		background-image: url("/assets/images/articles/dots.svg");
		background-repeat: no-repeat;
		background-size: contain;

		transform: translateX(_s.rem(-8px));
	}

	@include _b.at("viewport-7") {
		flex-basis: _s.rem(200px);
		height: _s.rem(200px);
		margin: _s.rem(16px);

		&::before {
			width: 100%;
			height: 100%;

			transform: translateX(_s.rem(-8px)) translateY(_s.rem(12px, false));
		}
	}
}

.c-article-preview__content {
	margin: _s.rem(8px);

	@include _b.at("viewport-7") {
		margin: _s.rem(16px);
	}
}

.c-article-preview__tag {
	margin-bottom: _s.step(3);
}

.c-article-preview__title-container {
	position: relative;
}

.c-article-preview__title {
	@include _t.set("h5");
}

.c-article-preview__description-container {
	position: relative;
}

.c-article-preview__description {
	margin-top: _s.step(1);

	// Directly style elements comming from the Craft redactor
	p {
		@include _t.set("p");
	}
}

.c-article-preview__author {
	margin-top: _s.step(2);
}

// =============================================================================
// States
// =============================================================================
.c-article-preview__link {

	&:hover {

		& + .c-article-preview__title {
			text-decoration: underline;
		}
	}
}
