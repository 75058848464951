// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "./config/typography" as typography;
@use "./settings/borders" as borders;

// =============================================================================
// Root
// =============================================================================
.c-contact-hero {
	/**
	 * Try to keep the hero padding consistent troughout the site and use
	 * the same padding top as the default hero.
	 */
	padding-top: _s.step(5);
	padding-bottom: 0;

	text-align: center;

	background-color: _c.set("background dark");
	color: _c.set("text white");

	@include _b.at("viewport-7") {
		padding-bottom: _s.step(8);
	}

	@include _b.at("viewport-9") {
		padding-top: _s.step(8);
		padding-bottom: _s.step(10);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-contact-hero__description {
	margin-top: _s.step(1);
}

.c-contact-hero__actions {
	margin-top: _s.step(2);

	@include _b.at("viewport-7") {
		justify-content: center;
		align-items: center;
	}
}

.c-contact-hero__action {
	@include _b.at("viewport-7") {
		width: auto;
		min-width: _s.rem(280px);
	}
}

.c-contact-hero__form {
	margin-top: _s.step(4);
	padding: _s.step(2);

	text-align: left;

	background-color: _c.set("background white");
	color: _c.set("text title");

	@include _b.at("viewport-7") {
		padding: _s.step(3);
	}
}

.c-contact-hero__map {
	margin-top: _s.step(5);

	// Directly style the map
	.c-map__map {
		mix-blend-mode: luminosity;
	}
}
