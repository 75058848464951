// =============================================================================
// Reset
// =============================================================================

/**
 * Reset file which sits upon normalize.scss and is manually defined based off:
 *
 * https://meyerweb.com/eric/tools/css/reset/
 * https://gist.github.com/DavidWells/18e73022e723037a50d6
 *
 * Not all props are used from these stylesheets because we don't want to reset
 * every tag or let it influence accessibility
 */

// Reset both margin and padding on these elements
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
	margin: 0;
	padding: 0;
}

// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

// Remove generated quotes
blockquote,
q {
	quotes: none;
}

blockquote,
q {

	&::before,
	&::after {
		content: "";
		content: none;
	}
}
