// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Root
// =============================================================================
.c-intro {
	margin-top: _s.step(5);
	margin-bottom: _s.step(3);

	@include _b.at("viewport-7") {
		margin-top: _s.step(8);
		margin-bottom: _s.step(4);
	}

	@include _b.at("viewport-12") {
		margin-top: _s.step(12);
		margin-bottom: _s.step(6);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-intro__subtitle {
	@include _t.set("h6");
	margin-bottom: _s.step(1);

	color: _c.set("text highlight");
}

.c-intro__description {
	margin-top: _s.step(1);

	& > * {
		@include _t.set("description");
	}

	@include _b.at("viewport-9") {
		margin-top: _s.step(2);
	}
}
