// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-logo-carrousel {
	position: relative;
	z-index: zIndex.$base + 1;
}

// =============================================================================
// Elements
// =============================================================================
.c-logo-carrousel__header {
	padding: _s.step(2) 0;

	text-align: center;

	background-color: _c.set("background dark");
	color: _c.set("brand primary");
}

.c-logo-carrousel__title {
	@include _t.set("h6");
}

.c-logo-carrousel__body {
	position: relative;

	padding: _s.step(3) 0;

	background-color: _c.set("brand primary");
}

.c-logo-carrousel__wrapper {
	position: relative;
}

.c-logo-carrousel__clients {
	--c-logo-carrousel-clients-cols: 2;

	display: grid;
	gap: _s.step(4) _s.step(3);

	grid-template-columns: repeat(var(--c-logo-carrousel-clients-cols), 1fr);

	@include _b.at("viewport-4") {
		--c-logo-carrousel-clients-cols: 4;
	}

	@include _b.at("viewport-12") {
		--c-logo-carrousel-clients-cols: 8;
	}
}

.c-logo-carrousel__client {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	list-style: none;
}

.c-logo-carrousel__logo {
	width: 100%;
	max-width: _s.rem(120px);
	height: 100%;
	max-height: _s.rem(56px);
}

.c-logo-carrousel__buttons {
	position: absolute;
	top: calc(50% - (#{_s.rem(16px)} / 2));
	right: 0;
	left: 0;
	z-index: zIndex.$base + 1;

	display: flex;
	justify-content: space-between;
	width: 100%;
}

.c-logo-carrousel__button {
	background: none;
	outline: none;
	border: none;

	.c-logo-carrousel__button-icon {
		width: _s.rem(16px);
		height: _s.rem(16px);
	}
}

.c-logo-carrousel__button--prev {

	.c-logo-carrousel__button-icon {
		transform: rotate(90deg);
	}
}

.c-logo-carrousel__button--next {

	.c-logo-carrousel__button-icon {
		transform: rotate(-90deg);
	}
}
