// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;

// =============================================================================
// Variables
// =============================================================================
// Based on the l-section spacing and its modifiers
$_banner-spacing-base: _s.rem(-64px);
$_banner-spacing-mobile: _s.rem(-104px);

// =============================================================================
// Root
// =============================================================================
.c-banner {
	position: relative;
	z-index: zIndex.$base;

	padding: _s.step(2);

	background-color: _c.set("background primary");

	@include _b.at("viewport-7") {
		padding: _s.step(4);
	}
}

.c-banner-blog {
	max-width: 44.63rem;
	margin: 0 auto;
}

// =============================================================================
// Elements
// =============================================================================
.c-banner__content {
	display: flex;
	flex-direction: column;
	margin: _s.rem(-8px);

	@include _b.at("viewport-7") {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.c-banner__title {
	@include _t.set("h5");

	margin: _s.rem(8px);
}

.c-banner__button {
	align-self: flex-start;
	flex-shrink: 0;
	margin: _s.rem(8px);

	@include _b.to("viewport-4") {
		align-self: stretch;
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-banner--overlap {
	position: relative;

	margin-top: $_banner-spacing-mobile;
	margin-bottom: $_banner-spacing-mobile;

	@include _b.at("viewport-7") {
		margin-top: $_banner-spacing-base;
		margin-bottom: $_banner-spacing-base;
	}
}

.c-banner--secondary {
	background-color: _c.set("background secondary-dark");
	color: _c.set("text white");
}
