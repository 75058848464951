// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/colors" as _c;
@use "./config/typography" as typography;

// =============================================================================
// Root
// =============================================================================

/**
 * Scope class for hero descriptions coming from the Craft editor.
 */

.s-intro {

	p {
		@include _t.set("large");

		font-family: typography.$font-family-primary;
		font-weight: typography.$font-weight-light;
	}

	strong {
		font-weight: typography.$font-weight-semi-bold;
	}

	ul,
	ol {
		margin-top: _s.step(1);
		padding-left: _s.step(3);
	}

	a {
		text-decoration: none;
		word-break: break-all;

		color: _c.set("text highlight");
		border-bottom: solid 1px currentColor;

		transition: color 0.3s ease-in-out;

		&:hover {
			color: _c.set("text paragraph");
		}
	}
}
