// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Root
// =============================================================================
.c-reference {
	margin-top: _s.step(4);
	padding: _s.step(4);
	padding-left: _s.step(2);

	background-color: _c.set("background light");
	border-left: borders.$base;

	@include _b.at("viewport-7") {
		padding-left: _s.step(3);
	}

	p {
		@include _t.set("medium");

		margin-top: _s.step(4);
	}
}

.c-reference__content {

	p:first-child {
		margin-top: 0;
	}
}

.c-reference__source {

	p {
		@include _t.set("p");
	}
}
