/* stylelint-disable rule-empty-line-before */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-case {
	color: _c.set("text paragraph-inverse");
	background-color: _c.set("background dark");
}

// =============================================================================
// Elements
// =============================================================================
.c-case__wrapper {
	align-items: center;
}

.c-case__eyebrow {
	@include _t.set("h6");

	margin-bottom: _s.step(1);

	color: _c.set("text highlight");
}

.c-case__title {
	@include _t.set("h3");

	color: _c.set("text title-inverse");
}

.c-case__description {
	margin-top: _s.step(2);

	a {
		text-decoration: none;
		word-break: break-all;

		color: _c.set("text highlight");
		border-bottom: solid 1px currentColor;

		transition: color 0.3s ease-in-out;

		&:hover {
			color: _c.set("text paragraph");
		}
	}
}

.c-case__button {
	margin-top: _s.step(4);
}

.c-case__tags {
	margin-top: _s.step(4);
}

.c-case__tags-title {
	@include _t.set("h6");

	color: _c.set("text title-inverse");
}

.c-case__tags-list {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin: _s.step(1) _s.rem(-4px, false) _s.rem(-4px, false);
	padding: 0;

	list-style: none;
}

.c-case__tags-item {
	margin: _s.rem(10px, false) _s.rem(4px, false);
}

.c-case__quote-container {
	margin-top: _s.step(2);
	padding-left: _s.step(2);

	border-left: borders.$base;

	@include _b.at("viewport-7") {
		margin-left: _s.step(3);
		padding-left: _s.step(3);
	}
}

.c-case__quote {
	quotes: "\201c""\201d";

	// Target specific element comming from Craft Redactor
	p {
		&::before {
			content: open-quote;
		}

		&::after {
			content: close-quote;
		}
	}
}

.c-case__cite {
	display: block;
	margin-top: _s.step(2);

	font-style: normal;
}

.c-case__cite-name {
	@include _t.set("h6");

	display: block;
}

.c-case__cite-function {
	@include _t.set("small");

	display: block;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-case--light {
	background-color: _c.set("background white");
	color: _c.set("text paragraph");

	.c-case__title {
		color: _c.set("text title");
	}

	.c-case__tags-title {
		color: _c.set("text title");
	}
}

.c-case--right {
	.c-case__wrapper {
		@include _b.at("viewport-7") {
			flex-direction: row-reverse;
		}
	}
}
