// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-link {
	position: relative;

	display: inline-flex;
	flex-direction: column;

	text-decoration: none;

	color: _c.set("text title");
}

// =============================================================================
// Elements
// =============================================================================
.c-link__text {
	@include _t.set("p");
}

.c-link {

	&::after,
	&::before {
		content: "";

		position: absolute;
		bottom: _s.rem(-4px, false);
		left: 0;

		width: 100%;
		height: _s.rem(2px, false);
	}

	&::before {
		background-color: _c.set("border secondary");
	}

	&::after {
		background-color: _c.set("border secondary-dark");

		transition: transform 0.3s ease-out;
		transform: scaleX(0);
	}
}

.c-link__counter {
	position: absolute;
	top: 0;
	right: _s.rem(-20px, false);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-link--inverse {

	.c-link__text {
		color: _c.set("text white");
	}
}

.c-link--base-no-line {

	&::before {
		display: none;
	}
}

.c-link--secondary {

	&::after,
	&::before {
		height: _s.rem(1px, false);
	}

	&::before {
		background-color: _c.set("text paragraph-inverse");
	}

	.c-link__text {
		color: _c.set("text paragraph-inverse");
	}
}

// =============================================================================
// States
// =============================================================================
.c-link {

	&:hover {

		&::after {
			transform: scaleX(1);
		}
	}
}
