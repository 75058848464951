// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Typography
// =============================================================================

/**
 * Default and SIMPLE styles for all typographic tags. Anything else than the
 * font-style should be applied. NO MARGIN OR PADDING should be applied onto
 * these selectors.
 */

body {
	@include _t.set("body");
}

h1 {
	@include _t.set("h1");
}

h2 {
	@include _t.set("h2");
}

h3 {
	@include _t.set("h3");
}

h4 {
	@include _t.set("h4");
}

h5 {
	@include _t.set("h5");
}

h6 {
	@include _t.set("h6");
}

p {
	@include _t.set("p");
}

// strong {}

// small {}

// label {}

// a {}

// mark {}
