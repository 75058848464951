// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-quotes {
	background-color: _c.set("background light");
}

// =============================================================================
// Elements
// =============================================================================
.c-quotes__title {
	text-align: center;
}

.c-quotes__container {
	padding: _s.step(16) 0 _s.step(4);

	@include _b.at(viewport-7) {
		padding: _s.step(18) 0;
	}
}

.c-quotes__quotes {
	display: grid;
	margin-top: _s.step(5);
	gap: _s.step(10);

	@include _b.at(viewport-7) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.c-quotes__quote {
	max-width: _s.rem(480px);
	margin: 0 auto;
}

.c-quotes__quote--span {
	grid-column: 1/3;
}

.c-quotes__text {
	@include _t.set("description");

	position: relative;

	&::before,
	&::after {
		content: "";

		display: inline-block;
		width: _s.rem(40px);
		height: _s.rem(40px);

		background-image: url("/assets/images/quote/quote.svg");
		background-repeat: no-repeat;
	}

	&::before {
		position: absolute;
		top: _s.rem(-15px, false);
		left: _s.rem(-15px, false);
	}

	&::after {
		position: relative;
		right: _s.rem(15px, false);
		bottom: _s.rem(-15px, false);

		// little hack so the image doesn't enlarge the line-height
		width: _s.rem(10px, false);
		height: _s.rem(10px, false);

		font-size: 0;
		line-height: 0%;

		background-size: _s.rem(10px, false);

		transform-origin: center;
		transform: scale(-4);
	}
}

.c-quotes__text-quote {
	position: relative;
	z-index: zIndex.$base;
}

.c-quotes__credits {
	display: flex;
	margin-top: _s.step(4);
}

.c-quotes__avatar {
	flex-grow: 0;
	flex-shrink: 0;
	width: _s.rem(44px, false);
	height: _s.rem(44px, false);
	overflow: hidden;
	margin-right: _s.step(2);

	border-radius: 50%;
}

.c-quotes__author {
	@include _t.set("highlight");
}

.c-quotes__image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.c-quotes__company {
	color: _c.set("text highlight");
}
