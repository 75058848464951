// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-map {
	position: relative;

	width: 100%;
	height: _s.rem(420px, false);

	@include _b.at("viewport-7") {
		height: _s.rem(340px, false);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-map__map {
	position: absolute;
	top: 0;
	left: 0;
	z-index: zIndex.$base;

	width: 100%;
	height: 100%;
}

.c-map__info-container {
	position: relative;
	z-index: zIndex.$base + 2;

	padding: _s.step(2);

	@include _b.at("viewport-7") {
		width: _s.rem(320px);
		padding: _s.step(3);
	}
}

.c-map__info {
	padding: _s.step(2);

	text-align: left;

	background-color: _c.set("background white");
	color: _c.set("text paragraph");
}

.c-map__address-line {
	display: block;
}

.c-map__button {
	margin-top: _s.step(2);
	margin-bottom: _s.step(1);
}
