// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-badges {
	--c-badges-gap: #{_s.step(2.5)};

	display: flex;
	flex-wrap: wrap;

	gap: var(--c-badges-gap);

	list-style: none;

	@include _b.at("viewport-9") {
		justify-content: flex-end;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-badges__item {
	width: calc((100% - (var(--c-badges-gap) * 2)) / 3);
	max-width: 78px;
}

.c-badges__img {
	width: 100%;
	height: auto;
}

