// =============================================================================
// Lists
// =============================================================================

/**
 * Extension for normalize.scss and acts as a reset. It always sits on top of
 * the normalize.scss file. Logics based off:
 *
 * https://github.com/twbs/bootstrap/blob/master/scss/_reboot.scss
 */

// Remove trailing margins from nested lists.
li > {

	ol,
	ul {
		margin-bottom: 0;
	}
}
