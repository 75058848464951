@use "@littlemissrobot/sass-system/spacing" as _s;
@use "../settings/z-index" as zIndex;

.u-decoration\:top-left {
	position: relative;
	z-index: zIndex.$base;

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: zIndex.$background;

		width: _s.rem(600px);
		height: _s.rem(600px);

		background-image: url(/assets/images/footer/decoration.svg);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;

		transform: translate(-50%, -50%);

		pointer-events: none;
	}
}
