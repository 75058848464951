// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
// .c-collapse {}

// =============================================================================
// Elements
// =============================================================================
.c-collapse__header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding: _s.step(1) 0;

	cursor: pointer;
}

.c-collapse__icon {
	position: relative;

	width: _s.rem(16px);
	height: _s.rem(16px);

	color: inherit;

	transition: transform 0.3s ease-in-out;
}

.c-collapse__content {
	height: 0;
	overflow: hidden;

	/**
	 * Animating height is not a good option, but we have no other choice if
	 * we want the content below to reflow when toggling the collapse.
	 */
	transition: height 0.3s ease-in-out;
}

// =============================================================================
// States
// =============================================================================
.c-collapse {

	&.is-open {

		> .c-collapse__content {
			height: auto;
		}

		> .c-collapse__header {

			.c-collapse__icon {
				transform: rotate(180deg);
			}
		}
	}

	&.is-resizing {

		> .c-collapse__content {
			transition: none;
		}
	}
}
