/* stylelint-disable font-family-name-quotes */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "./config/typography" as typography;

// =============================================================================
// Root
// =============================================================================
.c-hero {
	/**
	 * Try to keep the hero padding consistent troughout the site and update
	 * custom heros as well when updating this component.
	 */
	padding-top: _s.step(5);
	padding-bottom: _s.step(3);

	@include _b.at("viewport-9") {
		padding-top: _s.step(12);
		padding-bottom: _s.step(12);
	}

	/**
	 * The sibling that comes right after the hero will always have an
	 *'l-section'-class, that defines the padding-top and -bottom of that element.
	 * If both this sibling and the hero itself have no background color, the
	 * section-padding will cause too much white space in between. That's
	 * why we remove some spacing from the top of that element.
	 */
	&:not(.c-hero--inverted),
	&:not(.c-hero--with-image),
	&:not(.c-hero--decorated) {

		+ .l-section:not([class*="u-background-color"]) {
			padding-top: _s.step(2);
		}
	}
}

.c-hero__container {
	width: 40%;
	margin: 0;
	padding: 0;

	@include _b.between("viewport-3", "viewport-7") {
		width: 100%;
		margin-bottom: _s.step(4);
	}
}

.c-hero__width {
	width: 100%;
}

// =============================================================================
// Elements
// =============================================================================
.c-hero__description {
	margin-top: _s.step(1);

	p:nth-child(2) {
		margin-top: _s.step(4);

		font-family: "Noto Sans", "Arial", sans-serif;
		font-size: 1.1rem;
		line-height: 1.75rem;
	}

	ul {
		margin-top: _s.step(1);

		font-family: "Noto Sans", "Arial", sans-serif;
		font-size: 1.1rem;
		line-height: 1.75rem;

		li {
			margin-top: _s.step(1);
		}
	}
}

.c-hero__form {
	margin-top: _s.step(4);
	padding: _s.step(2);

	text-align: left;

	background-color: _c.set("background white");
	color: _c.set("text title");

	@include _b.at("viewport-7") {
		padding: _s.step(3);
	}
}

// .c-hero__image {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// }

// .c-hero__image-source {
// 	/* IE11 */
// 	flex-shrink: 0;
// }

.c-hero__link-container {
	margin-top: _s.step(2);
}

.c-hero__link {
	min-width: _s.rem(180px, false);
}

.c-hero__animation-container {
	position: absolute;
	bottom: 0;
	left: 50%;

	width: _s.rem(320px);
	height: _s.rem(320px);

	transform: translate(-50%, 50%);

	@include _b.at("viewport-7") {
		width: _s.rem(720px);
		height: _s.rem(720px);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-hero--inverted {
	background-color: _c.set("background dark");
	color: _c.set("text white");

	@include _b.to("viewport-7") {
		text-align: center;
	}
}

.c-hero--with-decoration {

	@include _b.at("viewport-9") {
		position: relative;

		overflow: hidden;

		&::after {
			content: "";

			position: absolute;
			bottom: 0;
			left: 50%;

			width: _s.rem(530px, false);
			height: _s.rem(530px, false);

			background-image: url("/assets/images/hero/circle.svg");
			background-repeat: no-repeat;
			background-size: contain;

			transform: translateX(30%) translateY(265px);
		}
	}
}

.c-hero--with-image {

	.c-hero__content {

		@include _b.at("viewport-12") {
			margin-top: _s.step(8);
			margin-bottom: _s.step(4);
		}
	}
}

.c-hero--decorated {
	position: relative;

	overflow: hidden;
	padding-bottom: _s.rem(140px, false);

	text-align: center;

	background-color: _c.set("background dark");
	color: _c.set("text white");

	.c-hero__content {
		position: relative;
	}

	@include _b.at("viewport-7") {
		/*
		 * Make the hero as large as the viewport height
		 * minus the header and footer
		 */
		min-height: calc(100vh - #{_s.rem(80px)} - #{_s.rem(500px, false)});
		padding-top: _s.step(8);
		padding-bottom: _s.rem(320px);
	}

	@include _b.at("viewport-9") {
		padding-top: _s.step(12);
	}
}

.c-hero--small {
	padding-bottom: _s.step(1);
}
