// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/breakpoints" as _b;

// =============================================================================
// Root
// =============================================================================
.c-animated-globe {
	// Directly style the elements that are generated via JavaScript
	canvas {
		width: 100% !important; /* stylelint-disable-line */
		height: 100% !important; /* stylelint-disable-line */
	}
}
