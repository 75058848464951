// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/breakpoints" as _b;
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "settings/z-index" as zIndex;

// =============================================================================
// Root
// =============================================================================
.c-form-section {
	position: relative;
}

// =============================================================================
// Elements
// =============================================================================
.c-form-section__hero {
	margin-bottom: _s.step(4);
}

.c-form-section__title {
	margin-bottom: _s.step(1);

	@include _b.at("viewport-7") {
		margin-bottom: _s.step(2);
	}
}

.c-form-section__tagline {
	@include _t.set("h6");

	color: _c.set("text highlight");
}

.c-form-section__button {
	margin-top: _s.step(3);
}

.c-form-section__animation {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: zIndex.$background;

	width: 100%;
	max-width: _s.rem(800px);
	height: auto;
	margin: auto;

	@include _b.to("viewport-7") {
		top: unset;
		bottom: _s.rem(-200px);
	}
}

.c-form-section__form-container {
	padding: _s.step(2);

	background-color: _c.set("background white");
	box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.25);

	@include _b.at("viewport-7") {
		padding: _s.step(3);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-form-section--split {
	@include _b.at("viewport-7") {
		display: grid;
		gap: _s.step(4);

		grid-template-columns: 3fr 2fr;
		align-items: center;
	}
}

.c-form-section--align-top {
	align-items: start;
	margin-top: _s.step(4);

	.c-form-section__hero {
		margin-top: _s.step(4);
	}
}

.c-form-section--split-equally {
	grid-template-columns: 1fr 1fr;
}

.c-form-section--inverted {

	.c-form-section__hero {
		color: _c.set("text white");
	}
}
