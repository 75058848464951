// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "settings/borders" as borders;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-button {
	display: inline-block;
	padding: _s.rem(14px, false) _s.step(5);

	text-decoration: none;
	text-align: center;

	background-color: transparent;
	appearance: none;

	/**
	 * A transparent border so the button has an equal height
	 * and width as buttons with border
	 */
	border: transparent solid _s.rem(1px, false);

	transition: background-color 0.3s ease-out;

	@include _b.at("viewport-7") {
		position: relative;

		padding-right: _s.step(3);
		padding-left: _s.step(3);

		&::before {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			border: transparent solid _s.rem(1px, false);
			opacity: 0;

			pointer-events: none;
		}

		&:hover {
			&::before {
				opacity: 0;

				animation: pulse 1s ease-out;
			}
		}
	}
}

@keyframes pulse {
	0% {
		opacity: 0;

		transform: scale(1, 1);
	}

	15% {
		opacity: 0;
	}

	20% {
		opacity: 0.5;
	}

	100% {
		opacity: 0;

		transform: scale(1.1, 1.4);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-button__text {
	@include _t.set("button");

	color: _c.set("text title");
}

.c-button__flex {
	img,
	span {
		display: inline-block;
	}
}
// =============================================================================
// Modifiers
// =============================================================================
.c-button--primary {
	background-color: _c.set("brand primary");

	&::before {
		border-color: _c.set("brand primary");
	}

	&:hover {
		background-color: mix(
			_c.set("brand primary"),
			_c.set("brand secondary"),
			75%
		);
	}

	&.c-button--inverse {
		background-color: _c.set("background dark");

		&::before {
			border-color: _c.set("background dark");
		}

		&:hover {
			background-color: mix(
				_c.set("background dark"),
				_c.set("brand primary"),
				80%
			);
		}
	}
}

.c-button--inverse,
.c-button--secondary {
	.c-button__text {
		color: _c.set("text title-inverse");
	}
}

.c-button--secondary {
	background-color: _c.set("brand secondary");

	&::before {
		border-color: _c.set("brand secondary");
	}

	&:hover {
		background-color: mix(
			_c.set("brand secondary"),
			_c.set("brand primary"),
			75%
		);
	}
}

.c-button--outline {
	border: borders.$width-base solid _c.set("border secondary");

	&::before {
		border: borders.$width-base solid _c.set("border secondary");
	}

	&:hover {
		background-color: transparentize(_c.set("brand secondary"), 0.85);
	}
}

.c-button--small {
	padding-top: _s.step(1);
	padding-bottom: _s.step(1);
}

.c-button--icon {
	cursor: pointer;
}

// =============================================================================
// States
// =============================================================================
.c-button {
	user-select: none;

	&.is-disabled {
		opacity: 0.5;

		pointer-events: none;
	}
}
