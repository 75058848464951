// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-breakpoints" as _b;

// =============================================================================
// Variables
// =============================================================================
$_section-decorator-corner-base: _s.rem(70px, false);
$_section-decorator-corner-padding: _s.step(3);

// =============================================================================
// Root
// =============================================================================
// .l-section-decorator {}

// =============================================================================
// Elements
// =============================================================================
// .l-section-decorator__decoration {}

// =============================================================================
// Modifiers
// =============================================================================
// Squared dots in every corner
.l-section-decorator\:corners {
	position: relative;

	.l-section-decorator__decoration {
		position: absolute;
		top: 0;
		left: 50%;

		width: 100%;
		max-width: _s.rem(1800px);
		height: 100%;

		transform: translateX(-50%);

		&::before,
		&::after {
			content: "";

			position: absolute;
			top: 0;

			width: $_section-decorator-corner-base;
			height: 100%;

			background-repeat:
				no-repeat,
				no-repeat;
			background-image:
				url("/assets/images/section-decorator/corner-top.svg"),
				url("/assets/images/section-decorator/corner-bottom.svg");
			background-position:
				top $_section-decorator-corner-padding left $_section-decorator-corner-padding,
				bottom $_section-decorator-corner-padding left $_section-decorator-corner-padding;
			background-size:
				_s.rem(44px, false),
				_s.rem(44px, false);
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;

			transform: scaleX(-1);
		}
	}

	@include _b.to("viewport-7") {
		padding-top: $_section-decorator-corner-base + $_section-decorator-corner-padding;
		padding-bottom: $_section-decorator-corner-base + $_section-decorator-corner-padding;
	}
}

// A cut off circle in the corner top right
.l-section-decorator\:circle-top-right {
	position: relative;

	overflow-x: hidden;

	.l-section-decorator__decoration {
		position: absolute;
		top: 0;
		left: 50%;

		width: 100%;
		max-width: _s.rem(1440px);
		height: 100%;

		transform: translateX(-50%);

		&::before {
			content: "";

			position: absolute;
			top: 0;
			right: 0;

			width: _s.rem(344px);
			height: _s.rem(344px);

			background-repeat: no-repeat;
			background-image: url("/assets/images/section-decorator/circle-dark.svg");
			background-position: top right;
			background-size: contain;

			transform: translate(50%, -50%);

			@include _b.at("viewport-7") {
				width: _s.rem(526px, false);
				height: _s.rem(526px, false);
			}
		}
	}
}

// A cut off circle in the corner top left and bottom right
.l-section-decorator\:diagonal-circles {
	position: relative;

	overflow: hidden;

	.l-section-decorator__decoration {
		position: absolute;
		top: 0;
		left: 50%;

		width: 100%;
		max-width: _s.rem(1224px);
		height: 100%;

		transform: translateX(-50%);

		&::before,
		&::after {
			content: "";

			position: absolute;

			width: _s.rem(400px);
			height: _s.rem(400px);

			background-repeat: no-repeat;
			background-image: url("/assets/images/section-decorator/circle-black.svg");
			background-position: top left;
			background-size: contain;

			@include _b.at("viewport-7") {
				width: _s.rem(526px, false);
				height: _s.rem(526px, false);
			}
		}

		&::before {
			top: 0;
			left: 0;

			transform: translate(-55%, -55%);

			@include _b.to("viewport-7") {
				display: none;
			}
		}

		&::after {
			right: 0;
			bottom: 0;

			transform: translate(55%, 55%);
		}
	}

	@include _b.to("viewport-7") {
		padding-bottom: _s.step(10);
	}

	&.l-section-decorator\:diagonal-circles--dark {

		.l-section-decorator__decoration {

			&::before,
			&::after {
				background-image: url("/assets/images/section-decorator/circle-light.svg");
			}
		}
	}

	&.l-section-decorator\:diagonal-circles--light {

		.l-section-decorator__decoration {

			&::before,
			&::after {
				background-image: url("/assets/images/section-decorator/circle-dark.svg");
			}
		}
	}
}

.l-section-decorator\:diagonal-circles--small {

	.l-section-decorator__decoration {

		&::before {
			top: _s.rem(-80px);
			left: _s.rem(-80px);
		}

		&::after {
			right: _s.rem(-80px);
			bottom: _s.rem(-80px);
		}
	}
}

// A cut off circle in the top middle
.l-section-decorator\:circle-top-middle {
	position: relative;

	overflow: hidden;

	// Don't change the padding if there's an overlapping banner
	&:not(.l-section--spacing-before) {
		padding-top: _s.step(12);
	}

	.l-section-decorator__decoration {
		position: absolute;
		top: 0;
		left: 50%;

		width: _s.rem(260px, false);
		height: _s.rem(260px, false);

		background-repeat: no-repeat;
		background-image: url("/assets/images/section-decorator/circle-dark.svg");
		background-position: top right;
		background-size: contain;

		transform: translate(-50%, -70%);
	}

	@include _b.at("viewport-7") {
		padding-bottom: _s.step(2);
	}

	@include _b.at("viewport-9") {

		.l-section-decorator__decoration {
			width: _s.rem(520px);
			height: _s.rem(520px);

			transform: translate(-50%, -70%);
		}

		// Don't change the padding if there's an overlapping banner
		&:not(.l-section--spacing-before) {
			padding-top: _s.step(24);
		}
	}

	@include _b.at("viewport-12") {
		// Don't change the padding if there's an overlapping banner
		&:not(.l-section--spacing-before) {
			padding-top: _s.step(25);
		}
	}
}
// A cut off circle in the top middle
.l-section-decorator\:circle-top-middle--small {
	// Don't change the padding if there's an overlapping banner
	&:not(.l-section--spacing-before) {
		padding-top: _s.step(12);
	}

	.l-section-decorator__decoration {
		top: _s.rem(-20px, false);
	}

	@include _b.at("viewport-9") {

		.l-section-decorator__decoration {
			top: _s.rem(-80px, false);
		}

		// Don't change the padding if there's an overlapping banner
		&:not(.l-section--spacing-before) {
			padding-top: _s.step(18);
		}
	}

	@include _b.at("viewport-12") {
		// Don't change the padding if there's an overlapping banner
		&:not(.l-section--spacing-before) {
			padding-top: _s.step(20);
		}
	}
}
