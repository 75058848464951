/* stylelint-disable */
// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/box-shadow" as boxShadow;
@use "config/typography" as typography;

// =============================================================================
// Root
// =============================================================================

/**
 * Styling overrides for the Cookieyes cookie banner plugin, loaded via JavaScript.
 */

.cky-consent-bar {
	background-color: _c.set("background white") !important;
	background-image: url(/assets/images/pop-up/decoration.svg) !important;
	background-repeat: no-repeat !important;
	background-size: _s.rem(220px, false) _s.rem(260px, false) !important;
	background-position: top left !important;
	box-shadow: boxShadow.$box-shadow-large !important;
	border: none !important;

	a {
		color: _c.set("text highlight") !important;
	}
}

.cky-consent-title {
	font-family: typography.$font-family-primary !important;
	font-size: _s.rem(21px, false) !important;
	line-height: 1.2 !important;
	font-weight: typography.$font-weight-medium !important;
	color: _c.set("text title") !important;
}

.cky-bar-text {
	color: _c.set("text paragraph") !important;
}

.cky-btn {
	display: inline-block !important;
	padding: _s.step(1) !important;

	text-decoration: none !important;
	text-align: center !important;

	font-family: typography.$font-family-primary !important;
	font-weight: typography.$font-weight-medium !important;
	font-size: _s.rem(_s.$base-font-size) !important;
	line-height: _s.rem(24px) !important;
	letter-spacing: _s.rem(-1px, false) !important;

	background-color: transparent !important;
	appearance: none !important;
	border: transparent solid _s.rem(1px, false) !important;
	color: _c.set("text title") !important;

	transition: background-color 0.3s ease-out !important;

	@include _b.at("viewport-7") {
		padding-right: _s.step(3) !important;
		padding-left: _s.step(3) !important;
	}

	&:nth-child(odd) {
		border: borders.$width-base solid _c.set("border secondary") !important;

		&:hover {
			background-color: transparentize(_c.set("brand secondary"), 0.85) !important;
		}
	}

	&:nth-child(even) {
		background-color: _c.set("brand secondary") !important;

		&:hover {
			background-color: mix(_c.set("brand secondary"), _c.set("brand primary"), 75%) !important;
		}
	}
}

// Preferences modal
.cky-modal-content {
	padding: _s.step(2) 0 0;
	margin: 0 !important;

	border: none !important;
	background-color: _c.set("background dark") !important;
	background-image: url(/assets/images/pop-up/decoration-dark.svg);
	background-repeat: no-repeat !important;
	background-size: _s.rem(220px, false) _s.rem(260px, false) !important;
	background-position: top left !important;
	border-radius: 0 !important;

	* {
		font-family: typography.$font-family-primary !important;
		font-size: _s.rem(14px, false) !important;
		letter-spacing: _s.rem(-1px, false) !important;
		line-height: 1.5 !important;
	}

	.cky-tab-title {
		font-family: typography.$font-family-primary !important;
		font-size: _s.rem(21px, false) !important;
		line-height: 1.2 !important;
		font-weight: typography.$font-weight-medium !important;
		color: _c.set("text title-inverse") !important;
	}

	.cky-modal-close {
		visibility: hidden;

		color: _c.set("text title-inverse") !important;
		font-family: typography.$font-family-primary !important;
		font-size: _s.rem(21px, false) !important;

		transition: transform 0.3s ease-out;

		&::after {
			content: "×";
			visibility: visible;
			display: block;
			position: absolute;
			top: 0;
		}

		&:hover {
			transform: rotate(180deg);
		}
	}

	.cky-tab-item,
	.cky-tab-menu,
	.cky-tab-content {
		background-color: transparent !important;
	}

	.cky-tab-item {
		margin: _s.rem(4px, false) 0;
		padding: _s.rem(4px, false) 0 _s.rem(4px, false) _s.step(2);

		font-family: typography.$font-family-primary !important;
		font-size: _s.rem(13px, false) !important;
		line-height: 1.5 !important;
		font-weight: typography.$font-weight-semi-bold !important;
		letter-spacing: _s.rem(2px, false) !important;
		text-transform: uppercase !important;

		border: none !important;
		border-left: solid transparent borders.$width-base !important;

		transition: border 0.3s ease-in-out;

		&.cky-tab-item-active {
			border-left-color: _c.set("border secondary") !important;
		}
	}

	.cky-btn-custom-accept {
		padding-right: _s.step(2) !important;
		padding-left: _s.step(2) !important;
	}

	> div:last-child {
		background-color: transparent !important;
		color: _c.set("text paragraph-inverse") !important;
		border-top: borders.$dark;

		a {
			color: _c.set("text highlight") !important;
		}
	}

	@include _b.at("viewport-7") {
		padding: _s.step(5) _s.step(5) _s.step(1);
	}
}

.cky-modal-backdrop {
	background-color: _c.set("background secondary-dark") !important;
	opacity: 0.4 !important;
}
