// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Box shadow
// =============================================================================
$box-shadow: 0 _s.rem(4px, false) _s.rem(10px, false) _c.set("shadow base");

$box-shadow-large: 0 _s.rem(10px, false) _s.rem(20px, false) _c.set("shadow base"),
	0 _s.rem(20px, false) _s.rem(105px, false) _c.set("shadow dark");
