// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "settings/z-index" as zIndex;
@use "settings/box-shadow" as boxShadow;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-pop-up {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1 * zIndex.$foreground + 2;

	display: flex;
	align-items: center;
	width: 100vw;
	height: 100vh;

	opacity: 0;

	pointer-events: none;
}

// =============================================================================
// Elements
// =============================================================================
.c-pop-up__overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: zIndex.$foreground - 1;

	width: 100%;
	height: 100%;

	background-color: _c.set("background dark");
	opacity: 0;

	transition: opacity 0.3s ease-in-out 0.2s;
}

.c-pop-up__wrapper {
	position: relative;
	z-index: zIndex.$foreground + 1;

	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	padding: _s.step(8) _s.step(2) _s.step(5);

	background-color: _c.set("background white");
	background-image: url(/assets/images/pop-up/decoration-mobile-light.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: bottom center;
	opacity: 0;

	transform: scale(0.95);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

	@include _b.at("viewport-7") {
		width: 100%;
		height: 100%;
		max-height: 90vh;
		padding: _s.step(15) _s.step(7) _s.step(7);

		background-image: url(/assets/images/pop-up/decoration.svg);
		background-size: _s.rem(220px, false) _s.rem(260px, false);
		background-position: top left;
		box-shadow: boxShadow.$box-shadow-large;
	}

	@include _b.at("viewport-3") {
		height: auto;
	}
}

.video_popup_style {
	max-width: 100vw;

	@include _b.at("viewport-9") {
		max-width: 60vw;
	}
}

.video_popup_wrapper {
	overflow-y: hidden;
	padding: 0px;
}

.c-pop-up__close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;

	padding: 0px;

	appearance: none;
	color: _c.set("text paragraph");
	border: none;
	background-color: rgb(97, 255, 215, 0.5);

	transition: transform 0.3s ease-out;

	&:hover {
		transform: scale(0.8);
	}
}

.c-pop-up__description {
	@include _t.set("large");

	margin-top: _s.step(2);

	// Style redactor elements
	p {
		@include _t.set("large");
	}
}

.c-pop-up__footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.c-pop-up__footer-text {

	&:not(:last-child) {
		padding-right: _s.step(1);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-pop-up--dark {

	.c-pop-up__overlay {
		background-color: _c.set("background secondary-dark");
	}

	.c-pop-up__wrapper {
		background-color: _c.set("background dark");
		background-image: url(/assets/images/pop-up/decoration-dark.svg);

		@include _b.to("viewport-7") {
			background-image: url(/assets/images/pop-up/decoration-mobile-dark.svg);
		}
	}

	.c-pop-up__title {
		color: _c.set("text highlight");
	}

	.c-pop-up__close-icon,
	.c-pop-up__footer-text {
		color: _c.set("text white");
	}

	.c-pop-up__description {
		// Style redactor elements
		p {
			color: _c.set("text paragraph-inverse");
		}
	}
}

// =============================================================================
// States
// =============================================================================
.c-pop-up {

	&.is-open {
		z-index: zIndex.$foreground + 2;

		opacity: 1;

		pointer-events: auto;

		.c-pop-up__overlay {
			opacity: 0.4;

			transition: opacity 0.3s ease-in-out;
		}

		.c-pop-up__wrapper {
			opacity: 1;

			transform: scale(1);
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		}
	}
}
