// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;

// =============================================================================
// Radius
// =============================================================================
// $radius-base: ;
$radius-circle: 50%;

// =============================================================================
// Width
// =============================================================================
$width-base: _s.rem(1px, false);
$width-thick: _s.rem(2px, false);

// =============================================================================
// Border
// =============================================================================
$base: $width-base solid _c.set("border base");
$dark: $width-base solid _c.set("border inverse");

$active: $width-thick solid _c.set("brand secondary");

$form-element: $width-base solid _c.set("border outline");
