// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;

// =============================================================================
// Root
// =============================================================================
// .c-article-intro {}

// =============================================================================
// Elements
// =============================================================================
.c-article-intro__header {
	padding-bottom: _s.step(4);

	border-bottom: borders.$base;
}

.c-article-intro__title {
	@include _t.set("h2");
}

.c-article-intro__author {
	margin-top: _s.step(3);
}

.c-article-intro__meta {
	margin-bottom: _s.step(3);
}

.c-article-intro__tag {
	margin-right: _s.step(2);
}

.c-article-intro__sub-title {
	color: _c.set("text paragraph");
}

.c-article-intro__footer {
	margin-top: _s.step(8);
}

.c-article-intro__footer-item {
	padding: _s.step(4) 0;

	border-top: borders.$base;
	border-bottom: borders.$base;

	/*
	* TODO: Uncomment socials when linkedin issue is resolved by
	* simple share plugin
	*/
	// &:first-child {
	// 	border-bottom: none;
	// }
}
