// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;

// =============================================================================
// Root
// =============================================================================
.c-value {
	position: relative;

	padding-left: _s.step(3);

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: _s.rem(2px, false);
		height: _s.rem(30px, false);

		background-color: _c.set("border secondary-dark");

		transform-origin: 0 0;
		transition: transform 0.2s ease-in;
	}
}

// =============================================================================
// Elemens
// =============================================================================
.c-value__title {
	@include _t.set("h4");

	color: _c.set("text highlight");
}

.c-value__description {
	margin-top: _s.step(1);

	color: _c.set("text paragraph-inverse");
}
