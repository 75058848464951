// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "settings/borders" as borders;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-explanation {
	margin-top: _s.step(5);
	margin-bottom: _s.step(3);

	@include _b.at("viewport-7") {
		margin-top: _s.step(8);
		margin-bottom: _s.step(4);
	}

	@include _b.at("viewport-12") {
		margin-top: _s.step(12);
		margin-bottom: _s.step(6);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-explanation__intro {
	margin-top: _s.step(3);
}

.c-explanation__container {
	display: flex;
	margin-top: _s.step(4);

	@include _b.at("viewport-7") {
		margin-top: _s.step(8);
	}
}

.c-explanation__item {
	margin-top: _s.step(8);

	&:first-child {
		margin-top: _s.step(4);
	}

	&:last-child {
		height: auto;
		margin-bottom: _s.step(16);
	}

	@include _b.at("viewport-7") {
		height: 100vh;
		max-height: 40rem;
		margin-top: 0;
		margin-right: _s.step(10);
		padding: _s.step(12) 0;
	}
}

.c-explanation__item-eyebrow {
	@include _t.set("h6");

	color: _c.set("text highlight");
}

.c-explanation__item-title {
	margin-top: _s.step(1);
}

.c-explanation__item-description {
	margin-top: _s.step(2);
}

.c-explanation__item-button {
	margin-top: _s.step(2);
}

.c-explanation__image-container {
	position: relative;

	flex: 0 0 42%;
	padding-bottom: _s.step(8);

	@include _b.to("viewport-7") {
		display: none;
	}
}

.c-explanation__image-wrapper {
	position: sticky;
	top: calc(50vh - 300px);
	left: 0;

	width: 100%;

	@include _b.at("viewport-7") {
		height: _s.rem(600px);
	}
}

.c-explanation__image,
.c-explanation__base-image {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.c-explanation__image {
	opacity: 1;

	transform: scale(1);
	transition:
		transform 0.4s ease-in-out,
		opacity 0.4s ease-in-out;
}

.c-explanation__image-source {
	/* IE11 */
	flex-shrink: 0;
}

.c-explanation__mobile-image {

	@include _b.at("viewport-7") {
		display: none;
	}
}

// =============================================================================
// States
// =============================================================================
.c-explanation__image {

	&.is-hidden {
		opacity: 0;

		transform: scale(0.95);
	}
}
