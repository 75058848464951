// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/z-index" as zIndex;
@use "settings/borders" as borders;

// =============================================================================
// Variables
// =============================================================================
// $_[variable]: ;

// =============================================================================
// Root
// =============================================================================
.c-article-highlight {
	background-color: _c.set("background light");
	color: _c.set("text paragraph");
}

// =============================================================================
// Elements
// =============================================================================
.c-article-highlight__wrapper {
	align-items: center;
}

.c-article-highlight__link {
	position: absolute;
	top: 0;
	left: 0;
	z-index: zIndex.$base + 1;

	width: 100%;
	height: 100%;

	text-indent: -9999rem;
}

.c-article-highlight__image-container {
	position: relative;
}

.c-article-highlight__tag {
	margin-bottom: _s.step(3);
}

.c-article-highlight__title-container {
	position: relative;
}

.c-article-highlight__title {
	@include _t.set("h3");

	color: _c.set("text title");
}

.c-article-highlight__description-container {
	position: relative;
}

.c-article-highlight__description {
	margin-top: _s.step(2);
}

.c-article-highlight__author {
	margin-top: _s.step(3);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-article-highlight--right {

	.c-article-highlight__wrapper {

		@include _b.at("viewport-7") {
			flex-direction: row-reverse;
		}
	}
}

// =============================================================================
// States
// =============================================================================
.c-article-highlight__link {

	&:hover {

		& + .c-article-highlight__title {
			text-decoration: underline;
		}
	}
}
