// =============================================================================
// Use
// =============================================================================
@use "@littlemissrobot/sass-system/colors" as _c;
@use "@littlemissrobot/sass-system/spacing" as _s;
@use "@littlemissrobot/sass-system/typography" as _t;
@use "@littlemissrobot/sass-breakpoints" as _b;
@use "settings/borders" as borders;
@use "config/typography" as typography;

// =============================================================================
// Root
// =============================================================================
.c-footer {
	overflow-x: hidden;

	background-color: _c.set("background dark");
	color: _c.set("text paragraph-inverse");
}

// =============================================================================
// Elements
// =============================================================================
.c-footer__container {
	position: relative;

	padding: _s.step(6) 0 _s.step(4);

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: _s.rem(400px);
		height: _s.rem(400px);

		background-image: url(/assets/images/footer/decoration.svg);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: top left;

		transform: translate(-50%, -50%);

		pointer-events: none;
	}

	@include _b.at("viewport-7") {
		padding-top: _s.step(10);

		&::before {
			width: _s.rem(526px, false);
			height: _s.rem(526px, false);
		}
	}
}

.c-footer__cta {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding-bottom: _s.step(5);

	text-align: center;

	border-bottom: borders.$dark;

	@include _b.at("viewport-4") {
		align-items: flex-start;

		text-align: left;
	}

	@include _b.at("viewport-7") {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		border-bottom: none;
	}
}

.c-footer__cta-title {
	@include _t.set("h2");

	color: _c.set("text highlight");
}

.c-footer__cta-description {
	margin-top: _s.step(1);

	// Style redactor elements
	p {
		@include _t.set("description");
	}
}

.c-footer__cta-button {
	min-width: _s.rem(200px);
	margin-top: _s.step(6);

	@include _b.at("viewport-7") {
		margin-top: _s.step(1);
		margin-left: _s.step(8);
	}
}

.c-footer__main {
	margin-top: _s.step(4);
}

.c-footer__primary-nav {
	padding: 0;

	list-style: none;

	columns: 2;
	column-gap: _s.step(3);

	@include _b.at("viewport-12") {
		columns: 3;
	}
}

.c-footer__primary-nav-item {
	padding-bottom: _s.step(2);

	break-inside: avoid;
}

.c-footer__contact {
	display: flex;
	flex-direction: column;
	gap: _s.step(2);

	padding-top: _s.step(2);

	@include _b.at("viewport-7") {
		padding-top: 0;

		@include _t.set("small");
	}
}

.c-footer__socials-container {
	@include _b.to("viewport-9") {
		order: 4;
	}
}

.c-footer__socials {
	@include _b.at("viewport-9") {
		justify-content: flex-end;
	}
}

.c-footer__icon-label {
	@include _t.set("small");

	display: flex;
	align-items: flex-start;

	.c-footer__icon {
		margin-right: _s.step(1);

		// Some padding top to align the icon with the text
		padding-top: _s.rem(6px, false);
	}
}

.c-footer__icon {
	width: _s.rem(24px);
	height: _s.rem(24px);
}

.c-footer__mail {
	text-decoration: none;

	color: _c.set("text white");
}

.c-footer__secondary-nav-list {
	display: flex;
	flex-wrap: wrap;
	margin: _s.rem(-8px);
	padding: none;

	list-style: none;
}

.c-footer__secondary-nav-item {
	margin: _s.rem(8px);
}

.c-footer__secondary-nav-link {
	// Override the default link styling
	.c-link__text {
		@include _t.set("small");
	}
}

.c-footer__bottom-bar {
	margin-top: _s.step(3);
	padding-top: _s.step(2);

	border-top: borders.$dark;
}

.c-footer__disclaimer-container {
	@include _b.at("viewport-9") {
		display: flex;
		justify-content: flex-end;
	}
}

.c-footer__disclaimer {
	@include _t.set("small");
}
